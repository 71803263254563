<template>
  <basics
    v-model="parentShow"
    class="amount"
    @close="dialogClose"
    :mask-closable="false"
  >
    <!-- 查询优惠券（兑换与会员优惠券） -->
    <template v-if="!useAmount">
      <ul class="flex-start" slot="header">
        <li
          class="font-xl firts-li"
          @click="liClick(1)"
          :class="{ red: index == 1 }"
        >
          兑换优惠券
        </li>
        <li class="font-xl" @click="liClick(2)" :class="{ red: index == 2 }">
          使用会员优惠券
        </li>
      </ul>
      <div
        slot="content"
        class="keyboard-wrap"
        v-if="!memberInfo.mobile || index == 1"
      >
        <keyboard
          type="1"
          v-model="numbers"
          @ok="ok"
          shadow="2px 2px 5px 1px rgba(0, 0, 0, 0.3)"
          @clear="clear"
          :place="placeholder"
        >
        </keyboard>
      </div>
    </template>

    <!-- 查询到的兑换优惠券数据 -->
    <template v-if="useAmount && index == 1">
      <span slot="header"></span>
      <DiscountTabs
        slot="content"
        ref="exchangeYhq"
        :cur-type="1"
        @change="discountChange"
        :couponData="singleData"
        :order-data="orderData"
        @close="discountClose"
        @switchBrn="switchBrn"
      ></DiscountTabs>
    </template>

    <!-- 查询到的会员优惠券数据 -->
    <template v-if="useAmount && index == 2">
      <div slot="header" class="bd-b pb-4x">
        <p class="font-xl">
          <span class="mr-4x"
            >{{ memberInfo.name }}/{{ memberInfo.mobile }}</span
          >
          <span class="yellow ml-4x">
            <span class="iconfont icon-dengji font-xl"></span>
            {{ memberInfo.currentLevelName }}
          </span>
        </p>
      </div>
      <DiscountTabs
        slot="content"
        ref="memberYhq"
        :cur-type="2"
        @change="discountChange"
        :coupon-data="couponData"
        :order-data="orderData"
        @close="discountClose"
        @switchBrn="switchBrn"
      ></DiscountTabs>
    </template>

    <!-- 弹窗底部按钮 -->
    <!-- <div
      slot="footer"
      class="footer flex-center set-border"
      v-show="subIndex == 1 && useAmount"
    >
      <a-button type="primary" class="btn" @click="sure">
        确定选择
      </a-button>
    </div> -->
  </basics>
</template>
<script>
import Basics from "@/components/dialog/basics";
import Keyboard from "@/components/common/keyboard";
import DiscountTabs from "./discount-tabs.vue";
import { memberInfoOne } from "../../../../api/member";
import { mapActions } from "vuex";
import {
  windowCouponActivityList,
  windowRedeemCoupons
} from "../../../../api/marketing";
import { mapGetters } from "vuex";

export default {
  name: "DiscountAmount",
  components: {
    Basics,
    Keyboard,
    DiscountTabs
  },
  model: {
    prop: "show",
    event: "close"
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    orderData: {
      type: [Object, String],
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      index: 1,
      numbers: "", // 优惠券码、或者会员号、手机号
      placeholder: "请扫码或输入优惠券码",
      useAmount: false, // 是否由键盘，切换到使用优惠券
      parentShow: false, // 开启 basic基础弹窗
      singleData: [], //通过券码查询出的单个优惠券
      couponData: [], //优惠券data
      // 选择优惠券后，传过来的数据
      discountChangeData: {}
    };
  },
  computed: {
    ...mapGetters("common", ["memberId", "memberInfo"]),
    ...mapGetters("order", ["cartList"])
  },
  watch: {
    show(val) {
      if (val) {
        this.parentShow = true;
      }
    },
    memberId(val) {
      console.log(val, "-----**************");
      // 会员信息被清空
      if (!val) {
        this.switchBrn();
      }
    }
  },
  methods: {
    ...mapActions("common", ["updateMember"]),
    ...mapActions("order", [
      "setOrderDiscountTextList",
      "removeOrderDiscountTextList"
    ]),
    // 优惠券弹窗 tab
    liClick(i) {
      this.numbers = "";
      this.index = i;
      i == 1
        ? (this.placeholder = "请扫码或输入优惠券码")
        : (this.placeholder = "请扫码或输入会员号/会员手机号");
      if (this.memberInfo && this.memberInfo.mobile) {
        this.useAmount = true;
        //获取优惠券
        this.getActivityList();
      }
    },
    // 键盘清空
    clear() {
      this.numbers = "";
    },
    // 优惠券，键盘
    ok() {
      if (!this.numbers) {
        this.$message.warning("请先输入");
        return;
      }
      if (this.index === 1) {
        this.checkCode();
        return;
      }
      if (this.index === 2) {
        memberInfoOne({ mobile: this.numbers })
          .then(res => {
            if (!res.id) {
              this.$message.warning("查询不到会员信息");
              return false;
            }
            this.updateMember(res);
            //获取优惠券
            this.getActivityList();
            this.useAmount = true;
          })
          .catch(() => {});
      }
    },
    //查询单个优惠券
    checkCode() {
      let list = this.orderData.productItemList.map(item => {
        return {
          productId: item.productId,
          salePrice: item.sellingPrice,
          count: item.buyNum,
          merchantId: item.merchantId
        };
      });
      windowRedeemCoupons({
        couponCode: this.numbers,
        productItems: list
      })
        .then(res => {
          if (res) {
            this.singleData = res;
            this.useAmount = true;
          }
        })
        .catch(() => {});
    },
    // 获取选中的优惠券数据
    discountChange(row, index) {
      this.discountChangeData = row;
      this.discountChangeData.myIndex = index;
    },
    // 优惠券组件，emit过来的关闭指令
    discountClose() {
      this.reset();
    },
    // 右上角关闭弹窗
    dialogClose() {
      if (!this.useAmount) {
        this.reset();
        return;
      }
      if (this.index == 1) {
        this.$refs.exchangeYhq.removeSelect(true);
      } else {
        this.$refs.memberYhq.removeSelect(true);
      }
      this.reset();
    },
    reset() {
      this.$emit("close", false);
      this.numbers = "";
      this.parentShow = false;
    },
    switchBrn() {
      if (this.index == 1) {
        this.index = 2;
      } else {
        this.index = 1;
      }
      this.useAmount = false;
      this.clear();
    },
    //获取可用优惠券
    getActivityList() {
      let list = this.orderData.productItemList.map(item => {
        return {
          productId: item.productId,
          salePrice: item.sellingPrice,
          count: item.buyNum,
          merchantId: item.merchantId
        };
      });
      windowCouponActivityList({
        memberId: this.memberId,
        orderChannelName: "自助机",
        orderChannelId: 8, //8是自助机
        productItems: list
      })
        .then(res => {
          if (!res) {
            this.$message.warning("该会员没有可用优惠券");
          } else {
            this.couponData = res;
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";

.firts-li {
  margin-right: 80px;
}
.keyboard-wrap {
  box-sizing: border-box;
  padding: 140px 100px;
}

// 多选框
/deep/.ant-checkbox-inner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  &::after {
    width: 10px;
    height: 18px;
    left: 32%;
    top: 46%;
  }
}

/deep/.ant-modal-footer {
  padding: 10px 16px;
  border-top: 1px solid #e8e8e8;
}
</style>
