<!--
  抵扣支付
-->
<template>
  <div class="deduction-pay">
    <KeyboardPro
      width="520"
      type="2"
      @trigger="keyBoardInput"
      @keyBack="keyBack"
      @ok="doPay"
      v-show="propParam.subPayModeCode != 'MemberBalance'"
    >
      <!-- 开启组合支付时的input框 -->
      <template v-slot:input="{ confirm }">
        <a-input
          v-show="
            propParam.combinedPayment &&
              propParam.subPayModeCode != 'CashPledge'
          "
          slot="midSlot"
          placeholder="请输入收款金额"
          allow-clear
          size="large"
          style="margin-top: 13px;"
          class="prefix-input"
          v-model="money"
          @pressEnter="confirm"
          @focus="moneyFocus"
        >
          <span slot="prefix" class="primary">收款金额</span>
          <i
            style="color:#FFA735;"
            class="iconfont iconrenmingbiriyuan font-xxxl"
            slot="suffix"
          ></i>
        </a-input>
      </template>
      <!-- 非余额支付、押金支付需要的账号及密码input框 -->
      <div
        v-show="propParam.subPayModeCode != 'CashPledge'"
        slot="midSlot"
        style="margin-top:10px;"
      >
        <a-input
          style="width:300px;"
          allow-clear
          class="account-input"
          size="large"
          v-model="amount"
          placeholder="请输入账号"
          @focus="amountFocus"
        >
          <span class="primary font-xl" slot="prefix">账号：</span>
        </a-input>
        <a-input-password
          style="width:200px;margin-left:20px;"
          allow-clear
          size="large"
          v-model="passWord"
          @focus="passFocus"
          placeholder="请输入支付密码"
        ></a-input-password>
      </div>
      <!-- 押金支付 input 框 -->
      <div
        v-show="propParam.subPayModeCode == 'CashPledge'"
        slot="midSlot"
        style="margin-top:10px;"
      >
        <a-input
          allow-clear
          class="account-input"
          size="large"
          v-model="cashPledgeMember"
          placeholder="请输入会员码/会员手机号/票号"
          @focus="cashPledgeFocus"
          autocomplete="off"
        >
          <span slot="prefix">号码：</span>
        </a-input>
      </div>
    </KeyboardPro>
    <!-- 使用会员余额支付时 -->
    <div
      class="mt-2x font-xl"
      v-show="propParam.subPayModeCode == 'MemberBalance'"
    >
      <div class="mb-2x flex-start align-center">
        <span class="font-xxl mr-2x">当前使用的会员：</span>
        <a-button size="large" type="primary" @click="chnageMember">
          {{ memberId ? "更换会员" : "查询会员" }}
        </a-button>
      </div>
      <p v-show="!memberId" class="red">
        未查询到可用会员，请先查询会员再使用会员余额
      </p>
      <div v-show="memberId">
        <p>
          名称：
          <span class="gray">{{ memberInfo.name }}</span>
        </p>
        <p v-if="memberInfo.mobile">
          手机号：
          <span class="gray"
            >{{ memberInfo.mobile.substr(0, 3) }}
            ****
            {{ memberInfo.mobile.substr(7, 11) }}
          </span>
        </p>
        <p>
          可用余额：
          <span class="primary font-xxl">
            {{ memberInfo.buyBalance + memberInfo.giftBalance || 0 | money }}
          </span>
        </p>
      </div>
      <div
        v-show="memberId && !isScanMember"
        class="flex-start align-center mt-2x"
      >
        <span class="flex-shrink mr-2x">会员支付密码</span>
        <a-input-password
          allow-clear
          size="large"
          v-model="memberPassWord"
          class="member-pass"
          placeholder="请输入支付密码"
        ></a-input-password>
      </div>
      <a-button
        v-show="memberId"
        class="member-sure-btn"
        @click="memberSure"
        type="primary"
      >
        确认收款
      </a-button>
    </div>
    <!-- 登录会员弹窗 -->
    <a-modal
      centered
      v-model="showMemberLogin"
      :footer="null"
      title="查询会员"
      :width="700"
    >
      <!-- <FindMember @success="findSuccess"></FindMember> -->
      <searchMember @success="findSuccess"></searchMember>
    </a-modal>
  </div>
</template>
<script>
import KeyboardPro from "./KeyboardPro.vue";
import { payDeductionOrder, payCashpledgeOrder } from "@/api/order";
import { mapGetters, mapActions } from "vuex";
import searchMember from "@/components/common/search-member.vue";
// import FindMember from "../member/FindMember.vue";
import { myMixin } from "./mixin";

export default {
  name: "DeductionPay",
  components: {
    KeyboardPro,
    searchMember
    // FindMember
  },
  mixins: [myMixin],
  props: {
    propParam: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      money: "", // 收款金额
      mF: true, // 收款金额输入框，是否聚焦
      aF: false, // 账号输入框，是否聚焦
      amount: "", // 账号
      pF: false, // 支付密码是入口，是否聚焦
      passWord: "",
      showMemberLogin: false, // 是否显示登录会员弹窗
      memberPassWord: "", // 会员余额支付的支付密码
      isScanMember: false, // 是否通过扫会员码登录的会员
      memberCode: "", // 扫会员码得到的内容
      cashPledgeMember: "", // 押金支付：输入框内容
      cF: false // 押金支付：输入框是否聚焦
    };
  },
  computed: {
    ...mapGetters("common", ["memberId", "memberInfo"])
  },
  watch: {
    "propParam.subPayModeCode": {
      handler(val) {
        // 未开启组合支付
        if (!this.propParam.combinedPayment) {
          // 押金支付
          if (val == "CashPledge") {
            this.cF = true;
            this.aF = false;
            this.pF = false;
            this.mF = false;
          } else {
            this.aF = true;
            this.cF = false;
            this.pF = false;
            this.mF = false;
          }
        } else {
          this.aF = false;
          this.cF = false;
          this.pF = false;
          this.mF = true;
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("common", ["updateMember"]),
    doPay() {
      const isCashPledge = this.propParam.subPayModeCode == "CashPledge";
      // 押金支付
      if (isCashPledge) {
        if (!this.cashPledgeMember) {
          this.$message.warning("请先输入押金支付的号码");
          return;
        }
        this.cashPay();
        return;
      }
      // 非押金支付
      const params = {
        orderId: this.propParam.orderNumber, // 订单id
        payWayCode: this.propParam.subPayModeCode, // 线下支付传subPayModeId,其他支付传subPayModeCode
        payWayName: this.propParam.subPayModeName, // 支付方式名称
        payChannelCode: "", // 支付渠道Code
        payChannelName: "", // 支付渠道名称
        memberId: "", // 会员Id(使用会员余额抵扣时要提供)
        payAccountNo: this.amount, // 抵扣支付账号
        payAccountPwd: this.passWord, // 抵扣支付账号密码
        memberCode: "" // 扫会员码的情况，不需要输入会员支付密码
      };
      const isMember = this.propParam.subPayModeCode == "MemberBalance";
      // 选择了会员余额支付,且非扫会员码登录的会员
      if (isMember && !this.isScanMember) {
        params.memberId = this.memberId;
        params.payAccountPwd = this.memberPassWord;
        params.payAccountNo = "";
      } else if (isMember && this.isScanMember) {
        params.memberCode = this.memberCode;
        params.memberId = this.memberId;
        params.payAccountPwd = "";
        params.payAccountNo = "";
      }
      if (!this.amount && !isMember) {
        this.$message.warning("请先输入支付账号");
        return;
      }
      if (!this.passWord && !isMember) {
        this.$message.warning("请先输入支付密码");
        return;
      }
      payDeductionOrder(params)
        .then(() => {
          this.paySuccess();
        })
        .catch(() => {});
    },
    // 押金支付接口
    cashPay() {
      payCashpledgeOrder({
        orderId: this.propParam.orderNumber, // 订单号
        code: this.cashPledgeMember // 押金支付会员码\会员手机号\票号
      })
        .then(() => {
          this.paySuccess();
        })
        .catch(() => {});
    },
    // 键盘输入
    keyBoardInput(val) {
      if (this.propParam.combinedPayment && this.mF) {
        let str = this.money;
        str += String(val);
        this.money = str;
      } else if (this.aF) {
        let str = this.amount;
        str += String(val);
        this.amount = str;
      } else if (this.pF) {
        let str = this.passWord;
        str += String(val);
        this.passWord = str;
      } else if (this.cF) {
        let str = this.cashPledgeMember;
        str += String(val);
        this.cashPledgeMember = str;
      }
    },
    // 键盘退格
    keyBack() {
      if (this.propParam.combinedPayment && this.mF) {
        this.money = this.money.substr(0, this.money.length - 1);
      } else if (this.aF) {
        this.amount = this.amount.substr(0, this.amount.length - 1);
      } else if (this.pF) {
        this.passWord = this.passWord.substr(0, this.passWord.length - 1);
      } else if (this.cF) {
        this.cashPledgeMember = this.cashPledgeMember.substr(
          0,
          this.cashPledgeMember.length - 1
        );
      }
    },
    // 收款金额输入框聚焦
    moneyFocus() {
      // this.mF = true;
      // this.aF = false;
      // this.pF = false;
      // this.cF = false;
      this.changeFocus("mF");
    },
    // 账号输入框聚焦
    amountFocus() {
      // this.mF = false;
      // this.aF = true;
      // this.pF = false;
      // this.cF = false;
      this.changeFocus("aF");
    },
    // 支付密码输入框聚焦
    passFocus() {
      // this.mF = false;
      // this.aF = false;
      // this.pF = true;
      // this.cF = false;
      this.changeFocus("pF");
    },
    // 押金支付：输入框聚焦
    cashPledgeFocus() {
      // this.mF = false;
      // this.aF = false;
      // this.pF = false;
      // this.cF = true;
      this.changeFocus("cF");
    },
    // 处理input框失聚焦
    changeFocus(val) {
      this.mF = false;
      this.aF = false;
      this.pF = false;
      this.cF = false;
      this[val] = true;
    },
    // 查询会员成功回调
    findSuccess(memberId, num) {
      this.memberCode = num;
      // 是扫会员码登录会员
      if (num.length != 11) {
        this.isScanMember = true;
      } else {
        this.isScanMember = false;
      }
      this.showMemberLogin = false;
    },
    // 更换会员
    chnageMember() {
      this.showMemberLogin = true;
      this.updateMember({});
    },
    // 会员余额支付，确认收款
    memberSure() {
      if (!this.memberPassWord && !this.isScanMember) {
        this.$message.warning("请输入会员支付密码");
        return;
      }
      this.doPay();
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";

/deep/.ant-input.ant-input-lg {
  font-size: 18px;
}
.account-input /deep/.ant-input {
  padding-left: 64px;
}
.member-pass /deep/.ant-input-lg {
  height: 60px;
}
.member-sure-btn {
  width: 380px;
  height: 60px;
  background: @primary;
  box-shadow: 0px 5px 10px 0px rgba(83, 251, 139, 0.64);
  border-radius: 30px;
  margin: 40px 0 0 60px;
}
</style>
