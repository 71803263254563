<!--
  线下支付
-->
<template>
  <div class="offline-pay">
    <KeyboardPro
      v-show="propParam.combinedPayment"
      width="520"
      type="2"
      v-model="payMoney"
      @ok="doPay"
    >
      <template v-slot:input="{ confirm }">
        <a-input
          placeholder="请输入金额"
          v-model="payMoney"
          allow-clear
          size="large"
          class="prefix-input mt-1x"
          ref="scanRef"
          @pressEnter="confirm"
        >
          <span slot="prefix" class="primary">收款金额</span>
          <i
            style="color:#FFA735;"
            class="iconfont iconrenmingbiriyuan font-xxxl"
            slot="suffix"
          ></i>
        </a-input>
      </template>
    </KeyboardPro>
    <a-button
      class="offline-pay-btn"
      v-show="!propParam.combinedPayment"
      type="primary"
      @click="offlinePaySure"
    >
      确认收款
    </a-button>
  </div>
</template>
<script>
import KeyboardPro from "./KeyboardPro.vue";
import { payOfflineOrder } from "@/api/order";
import { myMixin } from "./mixin";

export default {
  name: "OfflinePay",
  components: { KeyboardPro },
  mixins: [myMixin],
  props: {
    propParam: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      payMoney: ""
    };
  },
  methods: {
    // 组合支付
    doPay(val) {
      console.log(val, "--*----");
    },
    // 非组合支付
    offlinePaySure() {
      payOfflineOrder({
        orderId: this.propParam.orderNumber,
        payWayCode: this.propParam.subPayModeId, // 线下支付传subPayModeId,其他支付传subPayModeCode
        payWayName: this.propParam.subPayModeName, // 支付方式名称
        payFlowNo: "", // 支付流水号
        payChannelCode: "", // 支付通道code
        payChannelName: "", // 支付通道名称
        ignoreFee: "" // 抹零金额
      })
        .then(() => {
          this.paySuccess();
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.ant-input.ant-input-lg {
  font-size: 18px;
}
.offline-pay-btn {
  width: 380px;
  height: 60px;
  background: #5396fb;
  box-shadow: 0px 5px 10px 0px rgba(83, 150, 251, 0.64);
  border-radius: 30px;
  margin: 40px 0 0 60px;
}
</style>
