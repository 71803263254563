<template>
  <basics
    :hide-default-header="true"
    v-model="parentShow"
    class="integral-deduction"
    @close="change"
  >
    <!-- 查询到可用积分 -->
    <template v-if="memberInfo.id">
      <div class="pb-4x bd-b" slot="header">
        <p class="font-xl mb-4x">
          <span class="mr-4x"
            >{{ memberInfo.name }}/{{ memberInfo.mobile }}</span
          >
          <span class="yellow ml-4x">
            <span class="iconfont icon-dengji font-xl"></span>
            {{ memberInfo.currentLevelName }}
          </span>
        </p>
        <p class="gray font-l">
          <span class="mr-1x">可用积分：</span>
          <span>{{ memberInfo.usableIntegral }}</span>
        </p>
        <a-button @click="changeMember" type="primary" class="mt-2x">
          更换会员
        </a-button>
      </div>
      <div
        slot="content"
        class="content bd round p-4x flex-start font-xl mv-4x"
      >
        <p class="primary">本次抵扣金额</p>
        <p class="ml-4x mr-1x">使用</p>
        <NumberBox
          :init="0"
          :step="rule.integralDeductionAmount"
          @minusClick="next => minusClick(next)"
          @plusClick="next => plusClick(next)"
        ></NumberBox>
        <p class="ml-1x mr-1x">积分抵扣</p>
        <p class="red">{{ (rule.integralDeductionMoney * count) | money }}元</p>
      </div>
      <div slot="footer" class="footer flex-center mt-4x set-border">
        <a-button type="primary" class="btn" @click="sureSelect">
          确定抵扣
        </a-button>
      </div>
    </template>
    <!-- 键盘 -->
    <div slot="content" class="dialog-body keyboard-wrap" v-if="!memberInfo.id">
      <search-member></search-member>
    </div>
  </basics>
</template>
<script>
import Basics from "@/components/dialog/basics";
import NumberBox from "@/components/common/number-box";
import SearchMember from "@/components/common/search-member.vue";
import { mapGetters, mapActions } from "vuex";
import { integralRule } from "../../../../api/member";

export default {
  name: "IntegralDeduction",
  components: {
    Basics,
    NumberBox,
    SearchMember
  },
  model: {
    prop: "show",
    event: "close"
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      parentShow: false,
      count: 0,
      rule: {
        integralDeductionAmount: 1, //默认每次递增1
        integralDeductionMoney: 0
      }
    };
  },
  computed: {
    ...mapGetters("common", ["memberInfo", "memberId"]),
    ...mapGetters("order", ["shouldPayMoney"])
  },
  watch: {
    show(val) {
      if (val) {
        this.parentShow = true;
      }
    },
    memberId(val) {
      // 会员信息被清空
      if (!val) {
        this.changeMember();
      }
    }
  },
  created() {
    //获取积分抵扣规则
    this.getIntegralRule();
  },
  methods: {
    ...mapActions("order", ["clearDiscount", "setIntegralDeduction"]),
    ...mapActions("common", ["updateMember"]),
    change() {
      this.$emit("close", false);
    },
    // 更换会员按钮
    changeMember() {
      this.updateMember({ id: "" });
      // 清空所有，已填的优惠金额
      this.clearDiscount();
      this.count = 0;
    },
    getIntegralRule() {
      integralRule()
        .then(res => {
          this.rule = res;
        })
        .catch(() => {});
    },
    sureSelect() {
      let { rule, count } = this;

      if (rule.integralDeductionMoney * count > this.shouldPayMoney) {
        this.$message.warning("抵扣金额不能大于应付金额");
        return false;
      }
      this.setIntegralDeduction({
        amount: rule.integralDeductionMoney * count,
        integralPayNum: rule.integralDeductionAmount * count,
        name: "积分抵扣"
      });
      this.parentShow = false;
      this.$emit("close", false);
    },
    plusClick(next) {
      this.count += 1;
      //如果抵扣金额（单位积分抵扣的钱*数量）大于应付金额
      if (this.rule.integralDeductionMoney * this.count > this.shouldPayMoney) {
        this.$message.warning("抵扣金额不能大于应付金额");
        this.count -= 1;
        return false;
      }
      //如果大于配置的积分抵扣上限
      if (
        this.rule.integralDeductionAmount * this.count >
          this.rule.integralDeductionLimit &&
        this.rule.integralDeductionLimit !== 0
      ) {
        this.$message.warning(
          "最多使用积分为：" + this.rule.integralDeductionLimit
        );
        this.count -= 1;
        return false;
      }
      //如果使用积分大于会员可用/剩余积分
      if (
        this.rule.integralDeductionAmount * this.count >
        this.memberInfo.usableIntegral
      ) {
        this.$message.warning("剩余积分不足");
        this.count -= 1;
        return false;
      }
      next();
    },
    minusClick(next) {
      this.count -= 1;
      next();
    }
  }
};
</script>
<style lang="less" scoped>
.keyboard-wrap {
  box-sizing: border-box;
  padding: 120px 100px;
}
</style>
