<!--
下单时，折扣优惠
-->

<template>
  <div class="discount bg-white shadow ph-4x pv-2x mh-x mb-4x">
    <div class="item flex-between font-xl">
      <span class="gray">应付金额</span>
      <span class="should-pay">{{ totalPrice | money }}</span>
    </div>
    <!-- 优惠券 -->
    <div class="item flex-between font-xl">
      <span class="gray">优惠券</span>
      <p @click="showAmountDialog = true">
        <span class="red" v-if="discountTextList[0]">
          - {{ discountTextList[0].amount | money }}
        </span>
        <span class="red" v-else> - {{ "0" | money }} </span>
        <a-icon type="right" class="gray ml-1x" />
      </p>
    </div>
    <!-- 积分抵扣 -->
    <div class="item flex-between font-xl bd-b">
      <span class="gray">积分抵扣</span>
      <p @click="showIntegralDeduction = true">
        <span class="red" v-if="integralTextList[0]">
          - {{ integralTextList[0].amount | money }}
        </span>
        <span class="red" v-else> - {{ "0" | money }} </span>
        <a-icon type="right" class="gray ml-1x" />
      </p>
    </div>
    <!-- 合计 -->
    <p class="font-xl total mt-2x text-r should-pay">
      合计：{{ shouldPayMoney | money }}
    </p>

    <!-- 优惠券 弹窗 -->
    <discount-amount
      v-model="showAmountDialog"
      :orderData="orderData"
    ></discount-amount>
    <!-- 积分抵扣 弹窗 -->
    <IntegralDeduction v-model="showIntegralDeduction"></IntegralDeduction>
  </div>
</template>
<script>
import DiscountAmount from "./components/discount-amount";
// import MemberBalance from "./components/member-balance";
import IntegralDeduction from "./components/integral-deduction";
import { mapGetters, mapActions } from "vuex";
import { shoppingCartList } from "../../../api/cart";

export default {
  name: "Discount",
  components: {
    DiscountAmount,
    // MemberBalance,
    IntegralDeduction
  },
  props: {
    orderData: {
      type: [Object, String],
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      showAmountDialog: false, // 优惠券 弹窗
      showIntegralDeduction: false, // 积分抵扣弹窗
      orderDiscount: 0, //优惠金额
      // memberBalanceNum: 0, // 会员余额抵扣
      integral: 0 //积分抵扣
    };
  },
  computed: {
    ...mapGetters("order", [
      "cartList",
      "discountTextList", // 优惠券，优惠的金额数据
      "integralTextList", // 积分抵扣优惠数据

      // "merchantDiscountAmount",
      "merchantDiscountStr",
      "shouldPayMoney",
      "discountId",
      "integralPayNum",
      "marketingMobile", //营销活动手机号
      "idCardNo", //身份证号
      "couponNo", //优惠券号
      "marketingId", //优惠券id
      "totalPrice", //购物车总金额
      "settingType", //整单折扣的配置类型：1.立减，2.折扣
      "customAmount" //整单折扣或者自定义金额
      // "integralPayPrice" //积分抵扣多少钱
      // "memberBalancePrice" //会员余额抵扣多少钱
    ]),
    ...mapGetters("setting", ["machineDetail"])
  },
  created() {
    this.getCartInfo();
    // // 是否可用会员余额支付
    // if (this.machineDetail && this.machineDetail.modeOfPayment) {
    //   const arr = this.machineDetail.modeOfPayment.split(",");
    //   if (arr.includes("3")) {
    //     this.useMemberBalance = true;
    //   } else {
    //     this.useMemberBalance = false;
    //   }
    // }
  },
  methods: {
    ...mapActions("order", ["clearDiscount", "setCartList"]),
    // // 会员余额
    // memberBalance(val) {
    //   this.memberBalanceNum = val;
    // },
    getCartInfo() {
      //获取购物车信息
      shoppingCartList({ id: this.$store.state.order.cartList.id }).then(
        res => {
          this.setCartList(res);
        }
      );
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";

.discount {
  border-radius: @2x;
  .should-pay {
    margin-right: 40px;
  }
  .item {
    line-height: 70px;
  }
  .total {
    line-height: 70px;
  }
}
</style>
