<!--
  支付成功页面
-->
<template>
  <div class="pay-success">
    <div class="pay-success-wrap">
      <div class="check-icon">
        <a-icon type="check" />
      </div>
      <p class="font-xxxxl">
        支付成功...
      </p>
      <p class="font-xl mt-1x">
        <span class="red">{{ timeNum }}</span>
        秒后将自动跳转返回
      </p>
      <a-button
        @click="continueSure"
        type="primary"
        size="large"
        class="btn-lg"
      >
        继续下单
      </a-button>
    </div>
    <!-- 打印进度弹窗 -->
    <a-modal
      :centered="true"
      :closable="false"
      :footer="false"
      :visible="visible"
      :mask-closable="false"
    >
      <img src="../../../assets/images/print.png" alt="print-img" />
      <p class="font-xl text-c bold">{{ process }}/{{ total }}</p>
      <p class="font-xl text-c bold">请勿离开！</p>
      <p class="font-xl text-c bold">门票正在出票中...</p>
    </a-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "PaySuccess",
  props: {},
  data() {
    return {
      timeNum: 60,
      timer: null,
      timer2: null,
      visible: false,
      process: 0,
      total: 0
    };
  },
  computed: {
    ...mapGetters("order", ["endOfPayConfig"]),
    ...mapGetters("common", ["printProcessDialog"])
  },
  watch: {
    printProcessDialog(val) {
      if (val) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    }
  },
  created() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timeNum = 60;
    }
    if (this.timer2) {
      clearTimeout(this.timer2);
    }
    this.payCountdown();
    // 提供方法供java调用，并获取其传入参数(打印进度弹窗)
    if (this.$store.state.common.printingRequired) {
      window.printProgress = this.printProgress;
    }
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    ...mapActions("order", ["setEndOfPayConfig"]),
    ...mapActions("common", ["updateMember"]),
    // 继续下单
    continueSure() {
      const url = this.endOfPayConfig.backUrl || "/home";
      // 是否需要清空会员信息
      if (this.endOfPayConfig.logoutMember) {
        this.updateMember({});
      }
      // 恢复配置信息到默认状态
      this.setEndOfPayConfig({
        logoutMember: true,
        backUrl: "/home"
      });
      this.$router.replace({ path: url });
    },
    // 支付倒计时
    payCountdown() {
      this.timer = setInterval(() => {
        // console.log("支付倒计时+++++++++");
        if (this.timeNum <= 0) {
          clearInterval(this.timer);
          // 跳转回首页
          this.continueSure();
          return;
        }
        this.timeNum = this.timeNum - 1;
      }, 1000);
    },
    printProgress(params) {
      console.log(params, "---*----打印进度回传数据-");
      if (params) {
        const arr = params.split(",");
        this.total = arr[0];
        this.process = arr[1];
        if (this.process >= this.total) {
          this.timer2 = setTimeout(() => {
            this.$store.dispatch("common/setPrintProcess", false);
            this.checkTicketType(); // 购买的门票，若有腕带票，提示去窗口取票
            clearTimeout(this.timer2);
          }, 1000);
        }
      }
    },
    // 查看购买的票，是否有腕带票
    checkTicketType() {
      const data = this.$store.state.order.cartList;
      if (data.productItemList && data.productItemList.length) {
        data.productItemList.forEach(item => {
          if (item.voucherType > 0) {
            this.$speak("购买的产品里有腕带票，请去窗口取票");
          }
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";
.pay-success-wrap {
  max-width: 600px;
  margin: 100px auto 0;
  text-align: center;
  .check-icon {
    width: 110px;
    height: 110px;
    text-align: center;
    line-height: 110px;
    background: #31ba61;
    color: #fff;
    border-radius: 50%;
    margin: 0 auto 30px;
    font-size: 58px;
  }
  .btn-lg {
    width: 253px;
    height: 60px;
    background: @primary;
    border-radius: 30px;
    margin-top: 50px;
  }
}
</style>
