<template>
  <div class="buy-order wrapper hei">
    <!-- 顶部进度条 -->
    <div class="top-bar relative bg-white container flex-shrink b-shadow">
      <a-steps :current="1">
        <a-step title="选票"></a-step>
        <a-step title="提交订单" />
        <a-step title="支付出票" />
      </a-steps>
    </div>

    <!-- 门票信息 -->
    <div class="order-wrap bg-white shadow p-4x mv-2x mh-x flex-grow slide">
      <template v-if="!cartList.productItemList">
        <a-empty />
      </template>
      <div
        class="bd order-box p-2x box-size mb-1x"
        v-for="item in cartList.productItemList"
        :key="item.productId"
      >
        <!-- 门票头像及标题 -->
        <div class="flex-start relative mb-2x">
          <img class="header-img" :src="item.productImage" />
          <div class="font-xl ml-3x">
            <!-- <span
              @click="deleteTicket(item)"
              class="iconfont icon-delete font-xl gray"
            ></span> -->
            <p class="text-1 title mb-2x">{{ item.productName }}</p>
            <!-- <p class="gray mid-text">门票类型：优惠票</p> -->
            <span class="red">{{ item.sellingPrice | money }}</span>
            <p>购买数量： {{ item.buyNum }}</p>
            <!-- <number-box
              class="num-box"
              :init="item.buyNum"
              @plusClick="
                () => {
                  return plusClick(item);
                }
              "
              @minusClick="
                () => {
                  return minusClick(item);
                }
              "
            ></number-box> -->
          </div>
        </div>
        <!-- 加购推荐产品 -->
        <div
          class="add-product font-xl flex-between line-h bd-t"
          v-if="item.whetherRecommend"
        >
          <span>加购推荐产品</span>
          <span class="red">{{ addPurchaseTotalPrice(item) | money }}</span>
          <a-button
            type="primary"
            class="font-xl"
            @click="increaseProduct(item)"
            >选择</a-button
          >
        </div>
        <!-- 快速通行服务 -->
        <div
          class="fast flex-between font-xl line-h bd-t"
          v-if="item.isSupportFastChannel"
        >
          <div @click="serviceDesc(item)">
            <span class="mr-2x">快速通行服务</span>
            <a-icon class="gray mr-1x" type="exclamation-circle" />
            <span class="gray">服务说明</span>
          </div>
          <span v-show="item.fastChannelType" class="red"
            >+{{ item.fastChannelPrice | money }}</span
          >
          <a-switch
            v-model="item.fastChannelType"
            @change="onChange($event, item)"
          />
        </div>
      </div>
    </div>
    <!-- 金额信息 -->
    <discount :orderData="cartList"></discount>

    <!-- 底部按钮 -->
    <div class="footer back-footer flex-between container">
      <p class="font-l">
        扫码支付:
        <span class="red">{{ shouldPayMoney | money }}</span>
      </p>
      <p @click="backEvent">
        《 返回
      </p>
      <a-button type="primary" class="btn" @click="sureBtn">确定支付</a-button>
    </div>

    <!-- 打印门票弹窗 -->
    <print-ticket v-model="showDialog"></print-ticket>
    <!-- 加购产品弹窗 -->
    <increase-products
      ref="increase"
      :increaseList="increaseList"
      :headerData="headerData"
      @updateCart="getCartInfo"
    ></increase-products>
    <!-- 快速通行服务说明 弹窗 -->
    <BasicsDialog v-model="serviceDescDialog" :hide-default-header="true">
      <div slot="header" class="bd-b pv-2x text font-xl mb-2x">
        <p class="mb-2x">快速通行服务</p>
        <!-- <p>
          <span class="gray">服务费用：</span>
          <span class="red">{{ 500 | money }}</span>
        </p> -->
      </div>
      <div slot="content" class="font-l">
        <div v-html="serviceDialogContent"></div>
      </div>
    </BasicsDialog>
    <!-- 查询结果弹窗 -->
    <Result
      :vis.sync="resultDialog"
      :text="resultText"
      btnText="返回首页"
      @customAction="resultOK"
    ></Result>
    <!-- 密码输入框弹窗 -->
    <BasicsDialog
      :visible="passwordDialog"
      :hide-default-header="true"
      @close="close"
    >
      <div slot="header" class="bd-b pv-x text font-xl mb-2x">
        <p class="mb-2x">请输入会员支付密码</p>
      </div>
      <div slot="content">
        <a-input
          class="mv-3x"
          type="password"
          v-model="memberPaymentPassword"
          placeholder="请输入支付密码"
        />
        <div class="flex-center">
          <a-button type="primary btn" @click="passwordSure">确定</a-button>
        </div>
      </div>
    </BasicsDialog>
  </div>
</template>
<script>
// import Back from "@/components/common/back";
// import NumberBox from "@/components/common/number-box";
import Discount from "@/components/common/discount/discount";
import PrintTicket from "@/components/dialog/print-ticket";
import IncreaseProducts from "@/views/buy/components/increase-products";
import BasicsDialog from "../../components/dialog/basics.vue";
import {
  shoppingCartList,
  updateShoppingCart,
  discountInfoCalculate,
  addPurchaseProduct
} from "../../api/cart";
import {
  productSelfFastremark,
  orderTicketprintinfo,
  orderProjectpackageprintInfo
} from "../../api/search";
import { orderPrint } from "../../api/order";
import { mapActions, mapGetters } from "vuex";
// import moment from "moment";
import Result from "../../components/dialog/result.vue";
import { updatePaper } from "../../utils/global";

export default {
  name: "BuyOrder",
  components: {
    // Back,
    // NumberBox,
    Discount,
    PrintTicket,
    IncreaseProducts,
    BasicsDialog,
    Result
  },
  props: {},
  data() {
    return {
      check: false,
      showDialog: false, // 显示 打印门票弹窗
      showIncrease: false, // 显示加购产品 弹窗
      increaseList: [], //推荐加购列表
      headerData: {}, //推荐加购列表头部数据
      serviceDescDialog: false,
      serviceDialogContent: "", // 快速通行服务说明
      backUrl: "/buy",
      memberPaymentPassword: "", //会员支付密码
      resultDialog: false,
      resultText: "支付成功，点击按钮返回首页",
      passwordDialog: false // 密码输入弹窗
    };
  },
  computed: {
    ...mapGetters("common", ["memberId"]),
    ...mapGetters("setting", ["menuData"]),
    ...mapGetters("order", [
      "cartList",
      "merchantDiscountAmount",
      "merchantDiscountStr",
      "shouldPayMoney",
      "discountId",
      "integralPayNum",
      "marketingMobile", //营销活动手机号
      "idCardNo", //身份证号
      "couponNo", //优惠券号
      "marketingId", //优惠券id
      "totalPrice", //购物车总金额
      "settingType", //整单折扣的配置类型：1.立减，2.折扣
      "customAmount", //整单折扣或者自定义金额
      // "integralPayPrice" //积分抵扣多少钱
      // "memberBalancePrice", //会员余额抵扣多少钱
      "discountTextList", // 订单折扣的优惠数据
      "integralTextList" // 积分抵扣的优惠数据
    ])
  },
  created() {
    if (this.$route.query.backUrl) {
      this.backUrl = this.$route.query.backUrl;
    }
    this.getCartInfo();
  },
  destroyed() {
    // 清空之前的会员信息
    // this.updateMember({ id: "" });
    this.clearDiscount(); // 清空优惠信息
  },
  methods: {
    // ...mapActions("cart", ["SetCartId", "clearCar"]),
    ...mapActions("order", ["setCartList", "clearDiscount"]),
    ...mapActions("common", ["updateMember"]),
    getCartInfo() {
      shoppingCartList({ id: this.$store.state.order.cartList.id }).then(
        res => {
          if (res) {
            this.setCartList(res);
          }
        }
      );
    },
    sureBtn() {
      // 是否需要输入支付密码
      if (this.integralTextList.length) {
        this.$speak("请输入支付密码");
        this.passwordDialog = true;
        return;
      }
      this.sureOrder();
    },
    sureOrder() {
      // 订单折扣-优惠数据
      const orderDis = this.discountTextList[0] || {};
      // 低分抵扣-优惠数据
      const orderInte = this.integralTextList[0] || {};
      discountInfoCalculate({
        id: this.cartList.id, //购物车id
        merchantDiscountStr: orderDis.merchantDiscountStr || "", //（整单折扣 A）（会员折扣 B）（优惠券 C）（营销活动 D）
        discountId: orderDis.discountId || "", //整单折扣id
        couponNo: orderDis.couponNo || "", //优惠卷号
        marketingId: orderDis.marketingId || "", //优惠活动id,优惠券id
        settingType: orderDis.settingType || "", //配置类型：1立减 2折扣
        customAmount: orderDis.customAmount || "", //整单折扣 使用 自定义金额
        idCardNo: orderDis.idCardNo || "", //身份证号
        memberMobile: this.$store.state.common.member.mobile || "", //会员手机号
        marketingMobile: orderDis.marketingMobile || "", //营销活动手机号
        memberId: this.memberId, //会员id
        merchantDiscountAmount: orderDis.amount || 0, //订单折扣抵扣多少钱
        memberBalancePrice: 0, //会员余额抵扣多少钱
        integralPayPrice: orderInte.amount || 0, //会员积分抵扣多少钱
        integralPayNum: orderInte.integralPayNum || 0, //会员积分抵扣数量
        memberPaymentPassword: this.memberPaymentPassword //会员输入支付密码
      })
        .then(res => {
          if (!res) {
            this.$message.error("确认订单失败，请重新尝试");
            return;
          }
          //创建订单成功后，就把购物车id清掉然后跳转
          this.setCartList({});
          this.$router.replace({
            path: "/scan-pay",
            query: {
              totalFee: res.settlementPrice,
              orderNumber: res.orderId,
              step: "true",
              print: this.$route.query.print || ""
            }
          });
        })
        .catch(() => {});
    },
    // 结果提示弹窗：确定
    resultOK() {
      // this.clearCar();
      this.setCartList({});
      const url = this.menuData[0].url;
      this.$router.replace(url);
    },
    //选择加购推荐产品
    increaseProduct(row) {
      this.headerData = row;
      this.getProductList(row);
    },
    // 快速通行服务说明
    serviceDesc(row) {
      productSelfFastremark({
        id: row.productId // 产品id
      })
        .then(res => {
          this.serviceDialogContent = res;
          this.serviceDescDialog = true;
        })
        .catch(() => {});
    },
    //获取加购推荐列表
    getProductList(row) {
      console.log(row);
      addPurchaseProduct({
        id: row.productId,
        spuId: row.spuId,
        dailyCalendar: row.playDate,
        cartId: this.$store.state.order.cartList.id
      }).then(res => {
        //如果本来已经存在加购列表，则把count加上去
        if (row.addPurchaseProductItemList) {
          res.forEach(item => {
            item.count = 0;
            //通过过滤匹配相同产品
            let target = row.addPurchaseProductItemList.filter(son => {
              return son.productId === item.id;
            });
            if (target.length > 0) {
              item.count = target[0].buyNum;
            }
          });
        } else {
          res.forEach(item => {
            item.count = 0;
          });
        }
        this.increaseList = res;
        this.$refs.increase.parentShow = true;
      });
    },
    // //本地清空购物车
    // clearCart() {
    //   this.SetCartId("");
    // },
    // 关闭密码弹窗
    close() {
      this.passwordDialog = false;
    },
    // 密码弹窗确定
    passwordSure() {
      if (!this.memberPaymentPassword) {
        this.$message.warning("请先输入密码");
        return;
      }
      this.passwordDialog = false;
      this.sureOrder();
    },
    //改变快票状态
    onChange(checked, row) {
      console.log(checked);
      this.setFast(row, checked);
    },
    setFast(row, checked) {
      let data = {
        id: this.cartList.id, //购物车id
        productId: row.productId, // 产品id
        spuId: row.spuId, // 产品的spuId
        whetherFastTicket: checked
      };
      this.updateSelf(data);
    },
    updateSelf(data) {
      updateShoppingCart(data).then(res => {
        if (!res) {
          //清空购物车
          // this.clearCart();
          this.setCartList({});
          return;
        }
        this.setCartList(res);
        this.clearDiscount(); // 清空优惠信息
      });
    },
    //计算加购产品价格
    addPurchaseTotalPrice(item) {
      let totalPrice = 0;
      if (item.addPurchaseProductItemList) {
        totalPrice = item.addPurchaseProductItemList.reduce((prev, current) => {
          return prev + current.buyNum * current.sellingPrice;
        }, 0);
      }
      return totalPrice;
    },
    // 顶部导航返回按钮
    backEvent() {
      // 若是填写游客信息页面，不清空购物车
      if (!this.$route.query.notclear) {
        // 清空购物车
        this.setCartList({});
      }
      this.$router.go("-1");
      // 清空所选优惠数据
      this.clearDiscount();
    },
    // 打印
    printTicket(orderNo) {
      // 系统初始化时，是否设置了门票打印模板，项目套餐打印模板
      const Hardware = localStorage.getItem("Hardware");
      let projectTempId = "";
      let ticketTempId = "";
      if (Hardware) {
        projectTempId = JSON.parse(Hardware).projectPrintTemplate;
        ticketTempId = JSON.parse(Hardware).machinePrintTemplate;
      }

      // 若是购买项目套餐，使用项目套餐的打印模板接口
      if (this.$route.query.print == "project") {
        this.printProject(orderNo, projectTempId);
        return;
      }

      orderTicketprintinfo({ orderId: orderNo, templateId: ticketTempId })
        .then(res => {
          if (res && res.length) {
            try {
              const bool = window.test.printTicket(JSON.stringify(res));
              if (!bool) {
                this.$speak("打印失败，请联系管理员查看打印机是否连接");
                this.$warning("打印失败，请联系管理员查看打印机是否连接");
                return;
              }
              this.$speak("打印门票中，请勿离开");
              this.showDialog = true;
              // 更新纸张数
              updatePaper(1, res.length, this);
              // 更改票的取票状态
              this.getTicket(orderNo);
            } catch (error) {
              this.showDialog = false;
              this.$speak("门票打印失败，请联系管理员");
              console.log(error, "打印失败方法，来到catch");
            }
          } else {
            this.$speak("购买的产品里有腕带票，请去窗口取票");
            this.resultText = "买的票中有腕带票，请去窗口取票";
            this.resultDialog = true;
          }
        })
        .catch(() => {
          console.log("打印门票接口报错啦啦啦");
        });
    },
    // 项目套餐 打印
    printProject(orderNo, projectTempId) {
      if (projectTempId) {
        this.$message.info("准备打印，请稍等");
        setTimeout(() => {
          orderProjectpackageprintInfo({
            orderId: orderNo,
            templateId: projectTempId
          })
            .then(res => {
              console.log(res, "项目套餐模板数据--+++++++");
              if (res) {
                try {
                  const bool = window.test.printTicket(JSON.stringify(res));
                  if (!bool) {
                    this.$speak("打印失败，请联系管理员查看打印机是否连接");
                    this.$warning("打印失败，请联系管理员查看打印机是否连接");
                    return;
                  }
                  this.$speak("打印中，请勿离开");
                  updatePaper(2, res.length, this);
                  this.showDialog = true;
                } catch (error) {
                  this.showDialog = false;
                  this.$speak("门票打印失败，请联系管理员");
                  console.log(error, "打印失败方法，来到catch");
                }
              }
            })
            .catch(() => {});
        }, 2000);
      }
    },
    // 更改取票的状态
    getTicket(orderNo) {
      // 如果成功调用打印门票，就掉接口通知后端已经取票
      orderPrint({
        channel: "", // 有登陆，不用传
        orderId: orderNo
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
// 顶部bar
.top-bar {
  height: 80px;
}

.order-wrap,
.order-box {
  border-radius: @2x;
}
.ant-input {
  height: 80px;
  font-size: 32px;
  border-radius: 8px;
}
.order-box {
  .header-img {
    width: 100px;
    height: 100px;
    border-radius: @2x;
  }
  .title {
    max-width: 550px;
  }
  .mid-text {
    line-height: 70px;
  }
  .num-box {
    position: absolute;
    right: @3x;
    bottom: @2x;
  }
  .icon-delete {
    position: absolute;
    right: @3x;
    top: @1x;
  }
  .line-h {
    line-height: 100px;
  }
  .add-product,
  .fast {
    button {
      width: 120px;
      height: 62px;
      border-radius: @4x;
    }
  }
  .fast {
    button {
      &::after {
        width: 58px;
        height: 58px;
        border-radius: 50%;
      }
    }
  }
}
</style>
