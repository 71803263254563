<template>
  <div class="basic-traveler-form">
    <!-- 人脸录入表单 -->
    <a-form-model
      ref="personIdForm"
      :model="formItemData"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 16 }"
      label-align="left"
      :rules="rules"
      v-if="formItemData.requiredPersonId"
    >
      <a-form-model-item
        label="人脸信息"
        prop="personId"
        :required="false"
        :rules="[
          {
            required: false,
            message: '请录入人脸'
          }
        ]"
      >
        <face-input
          :key="Math.random()"
          v-model="formItemData.personId"
          :echoimgurl="formItemData.personFaceUrl"
          @imgUrl="
            res => {
              formItemData.personFaceUrl = res;
            }
          "
        ></face-input>
      </a-form-model-item>
    </a-form-model>
    <!-- 其他表单 -->
    <a-form-model
      ref="ticketsInfoForm"
      :model="item"
      :rules="rules"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 16 }"
      label-align="left"
      v-for="(item, index) in formItemData.visitorFieldList"
      :key="index"
    >
      <!-- 手机号 -->
      <a-form-model-item
        ref="phone"
        :label="item.fieldName"
        :required="item.required"
        v-if="item.whetherSystemField && item.fieldType == 5"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入手机号',
            trigger: ['change', 'blur']
          },
          {
            type: 'number',
            message: '手机号需为数字'
          },
          {
            validator: checkPhone
          }
        ]"
      >
        <a-input
          @focus="inputFocus"
          @blur="inputBlur"
          v-model.number="item.fieldValue"
        />
      </a-form-model-item>
      <!-- 身份证号码 -->
      <a-form-model-item
        :label="item.fieldName"
        :required="item.required"
        v-if="item.whetherSystemField && item.fieldType == 6"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入身份证号',
            trigger: 'blur'
          },
          {
            validator: checkIdCard,
            message: '身份证格式错误',
            trigger: 'blur'
          }
        ]"
      >
        <IdCardInput
          @idcardFocus="inputFocus"
          @idcardBlur="inputBlur"
          @readCard="readCard"
          v-model="item.fieldValue"
        ></IdCardInput>
      </a-form-model-item>
      <!-- 姓名 -->
      <a-form-model-item
        ref="name"
        :label="item.fieldName"
        :required="item.required"
        v-if="item.whetherSystemField && item.fieldType == 4"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入姓名',
            trigger: ['change', 'blur']
          }
        ]"
      >
        <a-input
          @focus="inputFocus"
          @blur="inputBlur"
          v-model="item.fieldValue"
        />
      </a-form-model-item>
      <!-- 生日 -->
      <a-form-model-item
        :label="item.fieldName"
        :required="item.required"
        v-if="item.whetherSystemField && item.fieldType == 7"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请选择生日时间',
            triggger: 'change'
          }
        ]"
      >
        <a-date-picker
          v-model="item.fieldValue"
          type="date"
          :disabled-date="disabledDate"
          @change="
            (...params) => {
              item.fieldValue = params[1];
            }
          "
          style="width: 100%;"
          format="YYYY-MM-DD"
        />
      </a-form-model-item>
      <!-- 性别 -->
      <a-form-model-item
        :label="item.fieldName"
        :required="item.required"
        v-if="item.whetherSystemField && item.fieldType == 8"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请选择性别',
            triggger: 'change'
          }
        ]"
      >
        <a-select v-model="item.fieldValue">
          <a-select-option value="0">未知</a-select-option>
          <a-select-option value="1">女</a-select-option>
          <a-select-option value="2">男</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- 证件照 -->
      <a-form-model-item
        :label="item.fieldName"
        :required="item.required"
        v-if="item.whetherSystemField && item.fieldType == 9"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请拍摄证件照',
            triggger: 'change'
          }
        ]"
      >
        <picture-input class="m-x" v-model="item.fieldValue"></picture-input>
      </a-form-model-item>

      <!-- 自定义文本框 -->
      <a-form-model-item
        :label="item.fieldName"
        :required="item.required"
        v-if="!item.whetherSystemField && item.fieldType == 1"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '必填项不能为空',
            triggger: 'blur'
          }
        ]"
      >
        <a-input
          @focus="inputFocus"
          @blur="inputBlur"
          v-model="item.fieldValue"
        />
      </a-form-model-item>

      <!-- 自定义日期选择 -->
      <a-form-model-item
        :label="item.fieldName"
        :required="item.required"
        v-if="!item.whetherSystemField && item.fieldType == 2"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请选择日期',
            triggger: 'change'
          }
        ]"
      >
        <a-date-picker
          type="date"
          v-model="item.fieldValue"
          @change="
            (...params) => {
              item.fieldValue = params[1];
            }
          "
          style="width: 100%;"
          format="YYYY-MM-DD"
        />
      </a-form-model-item>

      <!-- 自定义下拉框-->
      <a-form-model-item
        :label="item.fieldName"
        :required="item.required"
        v-if="!item.whetherSystemField && item.fieldType == 3"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '必填项不能为空',
            triggger: 'blur'
          }
        ]"
      >
        <a-select v-model="item.fieldValue">
          <a-select-option
            v-for="(son, index) in item.fieldOptions"
            :value="son.fieldValue"
            :key="index"
            >{{ son.fieldValue }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import IdCardInput from "@/components/hardware/id-card-input";
import PictureInput from "../../../components/hardware/picture-input.vue";
import FaceInput from "@/components/hardware/face-input";
import { checkPhone, checkIdCard } from "../../../utils/global";
import moment from "moment";

export default {
  name: "BasicTravelerForm",
  components: {
    IdCardInput,
    PictureInput,
    FaceInput
  },
  props: {
    formItemData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      checkPhone,
      checkIdCard,
      rules: {}
    };
  },
  computed: {
    ...mapGetters("order", ["visitorsList", "purchaserMemberInfo"])
  },
  methods: {
    ...mapActions("order", ["setTouristInfo"]),
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    // 购票人信息表单检验
    validateForm() {
      let flagArr = [];
      let flag = true;
      let len = 0;
      if (this.$refs["ticketsInfoForm"]) {
        len = this.$refs["ticketsInfoForm"].length;
      }
      //验证其他动态表单
      if (this.$refs["ticketsInfoForm"] && len) {
        for (let i = 0; i < len; i++) {
          this.$refs["ticketsInfoForm"][i].validate(valid => {
            valid ? flagArr.push(true) : flagArr.push(false);
          });
        }
      }

      //验证人脸录入
      if (this.formItemData.requiredPersonId && this.$refs["personIdForm"]) {
        this.$refs["personIdForm"].validate(valid => {
          valid ? flagArr.push(true) : flagArr.push(false);
        });
      }

      // 是否所有必填项都通过
      for (let k = 0; k < flagArr.length; k++) {
        if (!flagArr[k]) {
          flag = false;
        }
      }
      return flag;
    },
    // input框的focus：告知父组件，增加padding，让键盘不盖住输入框
    inputFocus() {
      // const id = refName + index;
      // // 获取当前元素与顶部的距离
      // const distance = document.getElementById(id).getBoundingClientRect().top;
      // console.log(distance, "距离");
      this.$emit("distance");
    },
    // input框的blur
    inputBlur() {
      this.$emit("notDistance");
    },
    //身份证读取后信息
    readCard(info) {
      let { curPage } = this.$parent;
      if (this.visitorsList.length > 0) {
        let visitorsListCopy = JSON.parse(JSON.stringify(this.visitorsList));
        let travelerList = visitorsListCopy[curPage - 1].travelerList;
        if (travelerList && travelerList.length) {
          if (
            travelerList[0].visitorFieldList &&
            travelerList[0].visitorFieldList.length
          ) {
            travelerList[0].visitorFieldList.forEach(item => {
              if (item.fieldType == 4) {
                //姓名自动赋值
                item.fieldValue = info.name;
              }
            });
          }
        }
        this.setTouristInfo({
          visitorsList: visitorsListCopy,
          purchaserMemberInfo: this.purchaserMemberInfo
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";

/deep/.ant-form-item {
  font-size: 20px;
  margin-bottom: 16px;
}
/deep/.ant-form label {
  font-size: 20px;
}
/deep/.ant-checkbox-inner {
  width: 30px;
  height: 30px;
  &::after {
    width: 8px;
    height: 18px;
  }
}
/deep/.ant-input {
  height: 50px;
  font-size: 20px;
  border-radius: @1x;
}
// 表单校验提示语
/deep/.ant-form-explain {
  font-size: 18px;
}
</style>
