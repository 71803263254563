<template>
  <div class="discount-tabs">
    <div class="switch-btn mt-2x" @click="switchBtn">
      <a-icon type="swap" />
      {{ curType == 1 ? "使用会员优惠券" : "兑换优惠券" }}
    </div>
    <ul class="flex-start mb-4x mt-2x">
      <li
        class="font-xl firts-li"
        @click="subLiClick(1)"
        :class="{ red: subIndex == 1 }"
      >
        可用优惠券（{{ couponData1.length || 0 }}）
      </li>
      <li
        class="font-xl"
        @click="subLiClick(2)"
        :class="{ red: subIndex == 2 }"
      >
        不可用优惠券（{{ couponData2.length || 0 }}）
      </li>
    </ul>
    <!-- 可用优惠券 -->
    <ul v-show="subIndex == 1" class="ul-wrap slide pv-3x">
      <li
        @click="selectAmount(item, index)"
        class="li-item bd round flex-start mb-2x"
        v-for="(item, index) in couponData1"
        :key="index"
      >
        <div class="left flex white">
          <p class="font-xl text-c">
            {{ item.discountedPriceText | money }}
          </p>
          <p class="font-l text-c">{{ item.name }}</p>
        </div>
        <div class="right flex-between ph-2x">
          <div class="right-left font-l">
            <p class="text-2">
              <span>描述：</span>
              <span class="gray">{{ item.remark || "暂无" }}</span>
            </p>
            <p class="text-2">
              <span>有效期：</span>
              <span class="gray"
                >{{ item.startDate | date }} ~ {{ item.endDate | date }}</span
              >
            </p>
          </div>
          <div
            class="radios"
            :class="{ 'active-radio': selectAmountIndex === index }"
          >
            <a-icon
              v-show="selectAmountIndex === index"
              type="check-circle"
              theme="filled"
              class="primary icon"
            />
          </div>
        </div>
      </li>
    </ul>
    <!-- 不可用优惠卷 -->
    <ul v-show="subIndex == 2" class="ul-wrap slide pv-3x">
      <li
        class="li-item bd round flex-start mb-2x"
        v-for="(item, index) in couponData2"
        :key="index"
      >
        <div class="left flex not-use">
          <p class="font-xl text-c">{{ item.discountedPriceText | money }}</p>
          <p class="font-l text-c">{{ item.name }}</p>
        </div>
        <div class="right flex-between ph-2x">
          <div class="right-left font-l">
            <p class="text-2">
              <span>描述：</span>
              <span class="gray">{{ item.remark || "暂无" }}</span>
            </p>
            <p class="text-2">
              <span>有效期：</span>
              <span class="gray"
                >{{ item.startDate | date }} ~ {{ item.endDate | date }}</span
              >
            </p>
          </div>
        </div>
      </li>
    </ul>
    <!-- 底部按钮 -->
    <div class="flex-center bd-t">
      <a-button type="primary" class="btn mt-1x" @click="sure">
        确定选择
      </a-button>
    </div>
  </div>
</template>
<script>
import { windowActivityDiscountedPrice } from "../../../../api/marketing";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DiscountTabs",
  components: {},
  props: {
    curType: {
      type: Number,
      default: 1
    },
    couponData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    orderData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      subIndex: 1,
      selectAmountIndex: "", // 当前选中的优惠券索引
      selectAmountIndexCopy: "", // 当前选中的优惠券索引
      selectData: {}, // 当前选中的优惠券数据
      selectDataCopy: {} // 当前选中的优惠券数据
    };
  },
  computed: {
    ...mapGetters("order", [
      "discountTextList",
      "integralTextList",
      "totalPrice"
    ]),
    // 可用优惠券数据
    couponData1: function() {
      return this.couponData.filter(item => {
        return item.isCouponsAvailable;
      });
    },
    // 不可用优惠券数据
    couponData2: function() {
      return this.couponData.filter(item => {
        return !item.isCouponsAvailable;
      });
    }
  },
  methods: {
    ...mapActions("order", [
      "setOrderDiscountTextList",
      "removeOrderDiscountTextList"
    ]),
    ...mapActions("common", ["updateMember"]),
    // 切换可用与不可用优惠券
    subLiClick(i) {
      this.subIndex = i;
      this.$emit("subindex", i);
    },
    // 切换兑换与会员优惠券
    switchBtn() {
      this.removeSelect(false);
      this.$emit("switchBrn");
      this.updateMember({});
    },
    // 选中可用优惠券
    selectAmount(row, index) {
      // 点击同一个，取消优惠选择
      if (this.selectAmountIndex === index) {
        this.removeSelect(false);
        return;
      }
      const list = this.orderData.productItemList.map(item => {
        return {
          productId: item.productId,
          salePrice: item.sellingPrice,
          count: item.buyNum,
          merchantId: item.merchantId
        };
      });
      windowActivityDiscountedPrice({
        memberId: this.memberId,
        orderChannel: 8, //6是窗口
        productItems: list,
        preferentialList: [
          {
            preferentialType: "C", //C是优惠券，A是窗口折扣，B是会员折扣,D是营销活动
            businessId: row.id //优惠券id
          }
        ]
      })
        .then(res => {
          if (!res) {
            this.$message.warning("该优惠券不能使用");
            return;
          }
          this.selectAmountIndex = index; // 选中状态
          this.selectData = {
            name: "优惠券",
            amount: res.amount,
            merchantDiscountStr: "C",
            couponNo: row.couponCode,
            marketingId: row.id
          };
        })
        .catch(() => {});
    },
    // 确定按钮
    sure() {
      if (!this.selectData.name) {
        this.removeOrderDiscountTextList();
        this.selectAmountIndexCopy = "";
        this.selectDataCopy = {};
        this.$emit("close");
        return;
      }
      let money = 0;
      const selectInte = this.integralTextList.length > 0; // 是否有积分抵扣数据
      money = this.selectData.amount + money;
      money = selectInte && this.integralTextList[0].amount + money;
      console.log(money, "--*------");
      if (money > this.totalPrice) {
        this.$warning(
          `应付金额：${this.totalPrice / 100}元，已选的优惠金额：${money /
            100}元，优惠金额不能大于应付金额`
        );
        return;
      }
      this.selectAmountIndexCopy = this.selectAmountIndex;
      this.selectDataCopy = this.selectData;
      this.setOrderDiscountTextList(this.selectDataCopy);
      this.$emit("close");
    },
    // 取消选择(没点击确定按钮，点击右上角关闭按钮):isEcho是否需要回显旧数据
    removeSelect(isEcho) {
      this.selectAmountIndex = "";
      this.selectData = {};
      this.removeOrderDiscountTextList();
      // 若之前有选择，恢复之前选择数据
      if (this.selectAmountIndexCopy && isEcho) {
        this.selectAmountIndex = this.selectAmountIndexCopy;
        this.selectData = this.selectDataCopy;
        this.setOrderDiscountTextList(this.selectData);
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";

.switch-btn {
  width: 200px;
  height: 50px;
  border-radius: 20px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  border: 1px solid @primary;
}

.firts-li {
  margin-right: 80px;
}

.discount-tabs {
  .ul-wrap {
    max-height: 900px;
  }
  .li-item {
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
    .left {
      width: 40%;
      padding: 40px;
      box-sizing: border-box;
      background-color: @primary;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    .not-use {
      background-color: @bgGray;
    }
    .right {
      width: 60%;
      .right-left {
        width: 90%;
      }
      .text-2 {
        line-height: 56px;
      }
      .radios {
        border: 2px solid @border;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        .icon {
          font-size: 40px;
          position: relative;
          left: -2px;
          top: -3px;
        }
      }
      .active-radio {
        border: 2px solid @primary;
      }
    }
  }
}
</style>
