<template>
  <basics v-model="parentShow" @close="change" :headerData="headerData">
    <div slot="content">
      <p class="text-c font-xl pv-4x">可加购产品</p>
      <ul class="slide ph-2x ul" v-if="parentShow">
        <li
          class="li-item ph-4x pv-3x bd flex-start relative mb-2x"
          v-for="item in increaseList"
          :key="item.id"
        >
          <img :src="item.skuImages" />
          <div class="card-text-wrap ml-2x">
            <p class="font-xl text-1 mb-2x">{{ item.productName }}</p>
            <!-- <p class="font-l gray text-1">含：300游乐金，100喂食金</p> -->
            <div>
              <p class="red font-l">{{ item.price | money }}</p>
              <number-box
                class="num-box"
                :init="item.count"
                @plusClick="next => add(item, next)"
                @minusClick="next => cut(item, next)"
              ></number-box>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div slot="footer" class="footer flex-around mt-4x set-border">
      <p class="font-l">
        <span class="mr-2x">已选 {{ totalCount }} 张</span> 合计：<span
          class="red"
          >{{ totalPrice | money }}</span
        >
      </p>
      <a-button type="primary" class="btn" @click="sureSelect"
        >确定选择</a-button
      >
    </div>
  </basics>
</template>
<script>
import Basics from "@/components/dialog/basics";
import NumberBox from "@/components/common/number-box";
import { updateShoppingCart } from "../../../api/cart";
import { mapActions } from "vuex";

export default {
  name: "IncreaseProducts",
  components: {
    Basics,
    NumberBox
  },
  model: {
    prop: "show",
    event: "close"
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    increaseList: {
      type: [String, Array],
      default: function() {
        return [];
      }
    },
    headerData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      parentShow: false,
      customNum: 0
    };
  },
  computed: {
    totalCount: function() {
      let count = this.increaseList.reduce((prev, current) => {
        return prev + current.count;
      }, 0);
      return count;
    },
    totalPrice: function() {
      let count = this.increaseList.reduce((prev, current) => {
        return prev + current.count * current.price;
      }, 0);
      return count;
    }
  },
  watch: {
    show(newVal) {
      console.log(newVal);
      if (newVal) {
        // buy-order点击了打开弹窗，开启basices
        this.parentShow = true;
      }
    }
  },
  methods: {
    ...mapActions("order", ["clearDiscount"]),
    change() {
      // basices关闭了弹窗，关闭buy-order
      this.$emit("close", false);
    },
    //确定添加加购
    sureSelect() {
      let num = 0; // 用于判断，是否调整了加购产品，好清空已选择的优惠
      let pList = this.increaseList.map(item => {
        num += item.count;
        return {
          productId: item.id,
          spuId: item.spuId,
          buyNum: item.count
        };
      });
      // 初始化的情况，赋值
      if (this.customNum == 0) {
        this.customNum = num;
      }
      // 非初始化情况
      if (num != this.customNum) {
        // console.warn("加购产品变动了++++---------------++++");
        this.clearDiscount(); // 清空所有，已填的优惠金额
        this.customNum = num; // 清空优惠后，赋当前值
      }
      updateShoppingCart({
        id: this.$store.state.cart.id, //购物车id
        productId: this.headerData.productId,
        spuId: this.headerData.spuId,
        addProductList: pList
      })
        .then(res => {
          console.log(res);
          this.$emit("updateCart");
          this.parentShow = false;
          this.$emit("close", false);
        })
        .catch(() => {});
    },
    add(item, next) {
      item.count++;
      next();
    },
    cut(item, next) {
      if (item.count <= 0) {
        item.count = 0;
      } else {
        item.count--;
        next();
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";
.ul {
  max-height: 900px;
}
.li-item {
  border-radius: @2x;
  img {
    width: 150px;
    height: 150px;
    border-radius: @2x;
  }
  .card-text-wrap {
    max-width: 550px;
    p {
      line-height: 50px;
    }
    .num-box {
      position: absolute;
      right: @3x;
      bottom: @1x;
    }
  }
}
</style>
