<!--
  @name: 键盘组件
  @author: 冯炎龙
  @attr: {
    v-model: 最终输出键盘的值
    //键盘类型：1是只输入整数，2是输入两位小数的，3是输入身份证的
    type:{
      type: String,
      default() {
        return "1";
      }
    },
    // 键盘宽度
    width: {
      type: [String, number],
      default: 540
    }
  }
  @event:{
      ok:{   // 确定按钮的点击事件
          type:Function,
          default:null
      }
      change:  //input的值有变化时触发
  }
  @slot:input
  @describe:具体用法请参考member->findMember的组件
-->

<template>
  <div class="keyboard-box" :style="{ width: width + 'px' }">
    <slot name="input" v-bind:confirm="confirm"></slot>
    <slot name="midSlot"></slot>
    <div class="keyboard-wrapper flex-start mt-1x">
      <!--输入键-->
      <ul class="keyboard-list flex-start flex-wrap flex-grow">
        <li class="item" v-for="item in 9" :key="item">
          <a-button type="primary" @click="trigger(item)">{{ item }}</a-button>
        </li>
        <!--只输入整数-->
        <template v-if="type == '1'">
          <li class="item three bottom">
            <a-button type="primary" @click="trigger(0)">0</a-button>
          </li>
        </template>
        <!--两位小数-->
        <template v-if="type == '2'">
          <li class="item bottom">
            <a-button type="primary" @click="trigger(0)">0</a-button>
          </li>
          <li class="item bottom">
            <a-button type="primary" @click="trigger('00')">00</a-button>
          </li>
          <li class="item bottom">
            <a-button type="primary" @click="trigger('.')">.</a-button>
          </li>
        </template>
        <!--输入身份证-->
        <template v-if="type == '3'">
          <li class="item bottom two">
            <a-button type="primary" @click="trigger(0)">0</a-button>
          </li>
          <li class="item bottom">
            <a-button type="primary" @click="trigger('X')">X</a-button>
          </li>
        </template>
      </ul>
      <!--回车和确认-->
      <ul class="operate-list flex-col flex-between">
        <li class="item">
          <a-button class="btn-delete" type="primary" @click="back"
            ><span class="iconfont icon-tuige"></span
          ></a-button>
        </li>
        <li class="item mv-2x">
          <a-button class="btn-ok font-xxxl" type="primary" @click="confirm"
            >确定</a-button
          >
        </li>
      </ul>
      <!-- <ul class="operate-list flex-col flex-start flex-grow">
        <li class="item flex-shrink">
          <a-button class="btn-delete" type="primary" @click="back"
            ><span class="iconfont icon-tuige"></span
          ></a-button>
        </li>
        <li class="item">
          <a-button class="btn-ok" type="primary" @click="confirm"
            >确定</a-button
          >
        </li>
      </ul> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "keyboard",
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    // 类型
    type: {
      type: String,
      default() {
        return "1";
      }
    },
    // 宽度
    width: {
      type: [String, Number],
      default: 532
    }
  },
  data() {
    return {
      title: "键盘",
      code: ""
    };
  },
  watch: {
    value: {
      handler: function() {
        this.$emit("change");
      }
    }
  },
  created() {
    // 身份证读卡
    this.listen();
    // // 扫码
    // try {
    //   //开始监听扫描设备
    //   window.test.readEwm();
    //   //桥接方法供Java调用
    //   window.payScan = this.payScan;
    // } catch (error) {
    //   console.log(error);
    // }
  },
  methods: {
    // 身份证/IC读卡
    listen() {
      window.readIdCard = this.readIdCard;
    },
    readIdCard(res) {
      const cardInfo = JSON.parse(res);
      this.$emit("input", cardInfo.id);
      this.$emit("ok", cardInfo.id);
    },
    // 扫码
    payScan(code) {
      this.$emit("input", code);
      this.$emit("ok", code);
    },
    trigger(code) {
      let str = this.value;
      str += String(code);
      this.$emit("input", str);
      this.$emit("trigger", code);
    },
    back() {
      let str = this.value;
      str = str.substr(0, str.length - 1);
      this.$emit("input", str);
      this.$emit("keyBack");
    },
    confirm() {
      this.$emit("ok", this.value);
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";
/deep/.ant-input {
  height: 60px;
  font-size: 18px;
  border-radius: @1x;
}
/deep/.ant-input-clear-icon {
  font-size: 26px;
}
.keyboard-wrapper {
  .keyboard-list {
    width: 74%;
    .item {
      width: 31.33%;
      height: 100px;
      margin-right: 2%;
      margin-bottom: 10px;
      box-sizing: border-box;
      border-radius: @1x;
      &.one {
        width: 100%;
      }
      &.two {
        width: 64.66%;
      }
      > button {
        width: 100%;
        height: 100%;
        color: #000;
        background-color: #fff;
        border-color: #fff;
        border-radius: @1x;
        font-size: 28px;
      }
    }
  }

  .operate-list {
    width: 24%;
    justify-content: stretch;
    .item {
      width: 100%;
      > button {
        width: 100%;
        color: #fff;
        box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.3);
        border-radius: @1x;
      }
      .btn-delete {
        background: #75c4fc;
        border-color: #75c4fc;
      }
      .btn-delete,
      .btn-ok {
        height: 202px;
      }
      .icon-tuige {
        font-size: 54px;
      }
    }
  }
}
</style>
