import { orderPrint } from "../../../api/order";
import {
  orderTicketprintinfo,
  orderProjectpackageprintInfo
} from "../../../api/search";
import { updatePaper } from "../../../utils/global";

export const myMixin = {
  methods: {
    // 支付成功
    paySuccess(param) {
      this.$speak("支付成功");
      this.$router.replace({
        path: "/paySuccess"
      });
      const obj = param || {};
      // 以挂账方式支付的，根据后台接口返回结果，判断是否需要调打印功能
      if (obj.ifCanPrint) {
        console.log("挂账支付，不启用打印功能");
        return;
      }
      // 打印
      if (this.$store.state.common.printingRequired) {
        this.printTicket();
      } else {
        this.$store.dispatch("common/setPrintingRequired", true); // 重置是否显示打印组件
      }
    },
    // 打印
    printTicket() {
      // 系统初始化时，是否设置了门票打印模板，项目套餐打印模板
      const Hardware = localStorage.getItem("Hardware");
      let projectTempId = "";
      let ticketTempId = "";
      if (Hardware) {
        projectTempId = JSON.parse(Hardware).projectPrintTemplate;
        ticketTempId = JSON.parse(Hardware).machinePrintTemplate;
      }

      // 若是购买项目套餐，使用项目套餐的打印模板接口
      if (this.$route.query.print == "project") {
        this.printProject(this.propParam.orderNumber, projectTempId);
        return;
      }

      // 门票的打印
      orderTicketprintinfo({
        orderId: this.propParam.orderNumber,
        templateId: ticketTempId
      })
        .then(res => {
          if (res && res.length) {
            try {
              const bool = window.test.printTicket(JSON.stringify(res));
              if (!bool) {
                this.$speak("打印失败，请联系管理员查看打印机是否连接");
                this.$warning("打印失败，请联系管理员查看打印机是否连接");
                return;
              }
              this.$speak("打印门票中，请勿离开");
              // 更新纸张数
              updatePaper(1, res.length, this);
              // 显示打印进度弹窗
              this.$store.dispatch("common/setPrintProcess", true);
              // 更改票的取票状态
              this.getTicket(this.propParam.orderNumber);
            } catch (error) {
              this.$store.dispatch("common/setPrintProcess", false);
              this.$speak("门票打印失败，请联系管理员");
              console.log(error, "打印失败方法，来到catch");
            }
          } else {
            this.$speak("购买的产品里有腕带票，请去窗口取票");
          }
        })
        .catch(() => {
          console.log("打印门票接口报错啦啦啦");
        });
    },
    // 项目套餐 打印
    printProject(orderNo, projectTempId) {
      if (projectTempId) {
        orderProjectpackageprintInfo({
          orderId: orderNo,
          templateId: projectTempId
        })
          .then(res => {
            console.log(res, "项目套餐模板数据--+++++++");
            if (res) {
              try {
                const bool = window.test.printTicket(JSON.stringify(res));
                if (!bool) {
                  this.$speak("打印失败，请联系管理员查看打印机是否连接");
                  this.$warning("打印失败，请联系管理员查看打印机是否连接");
                  return;
                }
                this.$speak("打印中，请勿离开");
                // 更新纸张数
                updatePaper(2, res.length, this);
                // 显示打印进度弹窗
                this.$store.dispatch("common/setPrintProcess", true);
              } catch (error) {
                this.$store.dispatch("common/setPrintProcess", false);
                this.$speak("门票打印失败，请联系管理员");
                console.log(error, "打印失败方法，来到catch");
              }
            }
          })
          .catch(() => {});
      }
    },
    // 更改取票的状态
    getTicket(orderNo) {
      // 如果成功调用打印门票，就掉接口通知后端已经取票
      orderPrint({
        channel: "", // 有登陆，不用传
        orderId: orderNo
      });
    }
  }
};
