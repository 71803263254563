var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic-traveler-form"},[(_vm.formItemData.requiredPersonId)?_c('a-form-model',{ref:"personIdForm",attrs:{"model":_vm.formItemData,"label-col":{ span: 6 },"wrapper-col":{ span: 16 },"label-align":"left","rules":_vm.rules}},[_c('a-form-model-item',{attrs:{"label":"人脸信息","prop":"personId","required":false,"rules":[
        {
          required: false,
          message: '请录入人脸'
        }
      ]}},[_c('face-input',{key:Math.random(),attrs:{"echoimgurl":_vm.formItemData.personFaceUrl},on:{"imgUrl":function (res) {
            _vm.formItemData.personFaceUrl = res;
          }},model:{value:(_vm.formItemData.personId),callback:function ($$v) {_vm.$set(_vm.formItemData, "personId", $$v)},expression:"formItemData.personId"}})],1)],1):_vm._e(),_vm._l((_vm.formItemData.visitorFieldList),function(item,index){return _c('a-form-model',{key:index,ref:"ticketsInfoForm",refInFor:true,attrs:{"model":item,"rules":_vm.rules,"label-col":{ span: 6 },"wrapper-col":{ span: 16 },"label-align":"left"}},[(item.whetherSystemField && item.fieldType == 5)?_c('a-form-model-item',{ref:"phone",refInFor:true,attrs:{"label":item.fieldName,"required":item.required,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入手机号',
          trigger: ['change', 'blur']
        },
        {
          type: 'number',
          message: '手机号需为数字'
        },
        {
          validator: _vm.checkPhone
        }
      ]}},[_c('a-input',{on:{"focus":_vm.inputFocus,"blur":_vm.inputBlur},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", _vm._n($$v))},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 6)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"required":item.required,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入身份证号',
          trigger: 'blur'
        },
        {
          validator: _vm.checkIdCard,
          message: '身份证格式错误',
          trigger: 'blur'
        }
      ]}},[_c('IdCardInput',{on:{"idcardFocus":_vm.inputFocus,"idcardBlur":_vm.inputBlur,"readCard":_vm.readCard},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 4)?_c('a-form-model-item',{ref:"name",refInFor:true,attrs:{"label":item.fieldName,"required":item.required,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入姓名',
          trigger: ['change', 'blur']
        }
      ]}},[_c('a-input',{on:{"focus":_vm.inputFocus,"blur":_vm.inputBlur},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 7)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"required":item.required,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请选择生日时间',
          triggger: 'change'
        }
      ]}},[_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","disabled-date":_vm.disabledDate,"format":"YYYY-MM-DD"},on:{"change":function () {
            var params = [], len = arguments.length;
            while ( len-- ) params[ len ] = arguments[ len ];

            item.fieldValue = params[1];
          }},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 8)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"required":item.required,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请选择性别',
          triggger: 'change'
        }
      ]}},[_c('a-select',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v("未知")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("女")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v("男")])],1)],1):_vm._e(),(item.whetherSystemField && item.fieldType == 9)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"required":item.required,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请拍摄证件照',
          triggger: 'change'
        }
      ]}},[_c('picture-input',{staticClass:"m-x",model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(!item.whetherSystemField && item.fieldType == 1)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"required":item.required,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '必填项不能为空',
          triggger: 'blur'
        }
      ]}},[_c('a-input',{on:{"focus":_vm.inputFocus,"blur":_vm.inputBlur},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(!item.whetherSystemField && item.fieldType == 2)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"required":item.required,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请选择日期',
          triggger: 'change'
        }
      ]}},[_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","format":"YYYY-MM-DD"},on:{"change":function () {
            var params = [], len = arguments.length;
            while ( len-- ) params[ len ] = arguments[ len ];

            item.fieldValue = params[1];
          }},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(!item.whetherSystemField && item.fieldType == 3)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"required":item.required,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '必填项不能为空',
          triggger: 'blur'
        }
      ]}},[_c('a-select',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}},_vm._l((item.fieldOptions),function(son,index){return _c('a-select-option',{key:index,attrs:{"value":son.fieldValue}},[_vm._v(_vm._s(son.fieldValue))])}),1)],1):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }