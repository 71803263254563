<template>
  <div class="wrapper hei relative">
    <!-- 顶部进度条 -->
    <div class="top-bar relative bg-white container flex-shrink b-shadow">
      <a-steps :current="0">
        <a-step title="选票"></a-step>
        <a-step title="提交订单" />
        <a-step title="支付出票" />
      </a-steps>
    </div>

    <!-- 左侧门票分类菜单 -->
    <div class="left-ticket-type r-shadow bg-white">
      <ul>
        <li
          :class="{ 'li-act': active == index, 'first-li': index == 0 }"
          class="li-item text-c"
          v-for="(item, index) in types"
          :key="index"
          @click="liClick(index, item.typeName)"
        >
          <p class="gray type-count">
            <span :class="{ primary: active == index }">
              {{ item.typeName }}
            </span>
            <br />
            (已选{{ item.typeCount }})
          </p>
        </li>
      </ul>
    </div>
    <!-- 主体内容 -->
    <div class="container slide flex-grow">
      <ul v-if="ticketList && ticketList.length">
        <li
          class="card flex-start relative li-item"
          v-for="item in ticketList"
          :key="item.id"
        >
          <img class="ticket-img mr-3x" :src="item.skuImages" />
          <div class="card-text-wrap">
            <p class="font-xl text-1">
              {{ item.name }}
            </p>
            <p class="font-l gray">门票类型：{{ item.categoryName }}</p>
            <p class="font-l gray">
              <span class="red mr-2x">{{ item.sellingPrice | money }}</span>
              <span class="text-dlt">{{ item.marketPrice | money }}</span>
            </p>
            <div>
              <p class="gray font-l notice" @click="openDialog(item)">
                <a-icon
                  class="gray mr-x"
                  type="exclamation-circle"
                  theme="filled"
                />
                购票须知
              </p>
              <number-box
                class="num-box"
                :init="item.count"
                @plusClick="next => plusClick(item, next)"
                @minusClick="next => minusClick(item, next)"
              ></number-box>
            </div>
          </div>
        </li>
      </ul>
      <a-empty v-else class="font-xl"></a-empty>
    </div>
    <!-- 底部按钮 -->
    <div class="footer back-footer flex-between container relative">
      <p class="font-l">
        已选 {{ ticketNum }} 张 合计：<span class="red">{{
          totalPrice | money
        }}</span>
      </p>
      <a-button type="primary" class="btn" @click="sureBuy">确定选择</a-button>
    </div>

    <!-- 购票须知弹窗 -->
    <basics v-model="showDialog" :headerData="headerData">
      <div
        slot="content"
        class="font-l p-2x"
        style="max-height:1000px;overflow-y: auto;"
      >
        <div v-html="showNotice"></div>
      </div>
    </basics>
    <!-- 分时预约票，弹窗选择 -->
    <basics v-model="appointmentDialog">
      <div slot="header" class="font-xl">
        选择使用时间段
      </div>
      <div slot="content" class="font-l p-2x" style="min-height:280px;">
        <ul class="flex-wrap ul-wrap white">
          <li
            @click="appointmentClick(item.timeIntervalId)"
            v-for="item in appointmentList"
            :key="item.id"
            :class="{ disable: isDisable(item) }"
          >
            <p>
              {{ item.beginTime | date("hh:mm") }} -
              {{ item.endTime | date("hh:mm") }}
            </p>
            <p>剩余库存：{{ item.remainStock }}</p>
          </li>
        </ul>
      </div>
    </basics>
    <!-- 查询结果弹窗 -->
    <Result :vis.sync="resultDialog" text="请先选择要购买的门票"></Result>
  </div>
</template>
<script>
import moment from "moment";
import NumberBox from "@/components/common/number-box";
import Basics from "@/components/dialog/basics";
import {
  productMerchantCategoryList,
  appointmentTimeRuleStock
} from "@/api/product";
import { productSelfBuyticket } from "../../api/search";
import { moneyFormat } from "../../utils/global";
import { mapActions, mapGetters } from "vuex";
import {
  shoppingCartList,
  addShoppingCart,
  updateShoppingCart,
  registerSettleAccounts
} from "@/api/cart";
import Result from "../../components/dialog/result.vue";

export default {
  name: "Buy",
  components: {
    NumberBox,
    Basics,
    Result
  },
  props: {},
  data() {
    return {
      types: [
        {
          typeName: "全部",
          typeCount: 0,
          spuId: ""
        }
      ], //过滤接口返回的门票类型
      selectType: "",
      allTickets: [], //接口返回的所有门票
      active: 0,
      num: 6,
      showDialog: false,
      headerData: {
        //传给弹窗组件的header数据
        productImage: "",
        productName: "",
        subTitle: "",
        settlementPrice: "",
        sellingPrice: ""
      },
      showNotice: "",
      cartData: [], //购物车的产品
      appointmentDialog: false, // 分时预约弹窗
      appointmentList: [], // 分时预约库存信息列表
      nowTime: 0,
      date: null,
      temporaryData: {}, // 分时预约票，临时数据
      temporaryNext: null,
      timeId: null, // 选中某个预约时段的id
      resultDialog: false
      // curTicketSelect: 0 // 当前门票分类，已选的数量
    };
  },
  computed: {
    ...mapGetters("order", ["cartList", "totalPrice", "ticketNum"]),
    ticketList: function() {
      //过滤选中的分类下的门票
      let tickets = this.allTickets.filter(item => {
        return item.categoryName === this.selectType;
      });
      // 显示：全部门票
      if (this.selectType == this.types[0].typeName) {
        tickets = this.allTickets;
      }

      //如果有选中的，把选中的数量补充回去
      if (this.cartData.length > 0) {
        tickets.forEach(item => {
          item.count = 0; // 先置空，后赋值
          this.cartData.forEach(oldItem => {
            if (oldItem.spuId === item.spuId) {
              item.count = oldItem.buyNum;
            }
          });
        });
        this.allSelectCount(tickets); // 左侧门票分类菜单，计算已选门票数量
      } else {
        // 处理 步进器 减少产品后的缓存
        tickets.forEach(item => {
          item.count = 0;
        });
        // 重置数量
        this.types.forEach(item => {
          item.typeCount = 0;
        });
      }
      return tickets;
    }
  },
  created() {
    this.setCartList({});
    // this.clearCar();
    // 每次购票，都先清空之前的会员信息（来自BUG：1007966）
    this.updateMember({ id: "" });
    this.clearDiscount(); // 清空优惠信息

    this.getTicketTypeList(); // 获取门票类型列表
    this.getCartInfo(); //如果有购物车，获取一次
    this.getTicketList(); //获取门票列表
    this.$speak("请选择需要购买的门票");
  },
  methods: {
    // ...mapActions("cart", ["SetCartId", "clearCar"]),
    ...mapActions("order", [
      "setCartList",
      "clearDiscount",
      "setTouristInfo",
      "setEndOfPayConfig"
    ]),
    ...mapActions("common", ["updateMember"]),
    // 获取门票类型列表 类型 1：门票，2：二销产品，3：资产类型，4：项目
    getTicketTypeList() {
      productMerchantCategoryList({ type: 1 })
        .then(res => {
          this.ticketTypeList = res;
        })
        .catch(() => {});
    },
    // 左侧门票分类菜单，计算当前每个分类，已选门票数量
    allSelectCount(list) {
      if (list.length <= 0) {
        return;
      }
      // 门票分类：全部
      this.types[0].typeCount = this.ticketNum;

      // 门票分类：其他分类
      list.forEach(item => {
        this.types.forEach(typeItem => {
          // 同一分类
          if (item.categoryName == typeItem.typeName) {
            // 同一分类，同一门票
            if (item.spuId == typeItem.spuId) {
              typeItem.typeCount = item.count;
            } else {
              // 同一分类，不同门票
              typeItem.typeCount = item.count + typeItem.typeCount;
            }
          }
        });
      });
    },
    //获取购物车拥有的信息
    getCartInfo() {
      return new Promise((resolve, reject) => {
        if (!this.cartList.id) {
          resolve();
          return;
        }
        shoppingCartList({ id: this.cartList.id })
          .then(res => {
            this.cartData = res.productItemList.map(item => {
              return {
                spuId: item.spuId,
                buyNum: item.buyNum
              };
            });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    getTicketList() {
      return new Promise((resolve, reject) => {
        productSelfBuyticket({
          page: 1,
          size: 999
        })
          .then(res => {
            if (res.total > 0) {
              this.allTickets = res.records;
              const arr = []; // 取出左侧门票分类名字
              this.types.forEach(typeItem => {
                arr.push(typeItem.typeName);
              });
              res.records.forEach(item => {
                // 所有门票里，还没在左侧分类里的，push进去
                if (!arr.includes(item.categoryName)) {
                  arr.push(item.categoryName);
                  this.types.push({
                    typeName: item.categoryName,
                    typeCount: 0,
                    spuId: item.spuId
                  });
                }
              });
              // 初始化时，高亮第一个分类
              this.selectType = this.types[0].typeName;
            }
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    liClick(index, typeName) {
      this.active = index;
      this.selectType = typeName;
      // 算出当前门票分类下，已选的门票数量
      if (index !== 0) {
        let count = 0;
        this.ticketList.forEach(item => {
          count = item.count + count;
        });
        this.types[index].typeCount = count;
      }
    },
    openDialog(item) {
      this.showDialog = true;
      this.showNotice = item.buyNotice;
      this.headerData = {
        productImage: item.skuImages,
        productName: item.name,
        playDate: `门票类型：${item.categoryName}`,
        settlementPrice: moneyFormat(item.sellingPrice),
        sellingPrice: moneyFormat(item.marketPrice)
      };
    },
    // 确定选择按钮
    sureBuy() {
      if (!this.ticketNum) {
        this.$speak("请先选择要购买的门票");
        this.resultDialog = true;
        return;
      }
      // 设置支付成功后，返回购票页面
      this.setEndOfPayConfig({
        logoutMember: true,
        backUrl: "/buy"
      });
      // 判断是否需要填写出游人信息
      registerSettleAccounts({ id: this.cartList.id })
        .then(res => {
          // 需要填写出游人信息、或者需要激活等
          if (res.needVisitorInfo) {
            this.saveTourisInfo(res); // 保存出游人资料到vuex
            this.$router.push({
              name: "records-buying"
            });
          } else {
            // 不需填写 游客资料
            this.$router.push({ name: "buy-order" });
          }
        })
        .catch(() => {});
    },
    // 保存出游人资料到vuex
    saveTourisInfo(res) {
      let visitorsList = [];
      res.productItemList.forEach(item => {
        let i = item.buyNum;
        while (i > 0) {
          let newItem = JSON.parse(JSON.stringify(item));
          //拆分成多个出游人信息: travelerList有多少条数据，就是填写多少个出游人的信息
          if (newItem.travelerList !== null) {
            visitorsList.push(newItem);
          }
          i--;
        }
      });
      this.setTouristInfo({
        visitorsList: visitorsList,
        purchaserMemberInfo: res.purchaserMemberInfo
      });
      console.log(
        "存储到VUEX的游客信息：",
        visitorsList,
        res.purchaserMemberInfo
      );
    },
    //产品加1
    plusClick(row, next) {
      let api = addShoppingCart,
        data = {
          productId: row.productId, // 产品id
          spuId: row.spuId, // 产品的spuId
          buyNum: 1, // 购买数量
          buyChannels: 8, // 购买渠道
          businessCategory: 1, // 业务类型
          playDate: moment().valueOf(), //游玩日期
          timeShareAppointmentRuleId: row.timeShareAppointmentRuleId, // 分时预约时段Id
          timeIntervalId: this.timeId
        };
      if (this.cartList.id) {
        api = updateShoppingCart;
        data.id = this.cartList.id;
      }
      api(data).then(res => {
        // 分时预约的票
        if (res && res.needTimeShareAppointmentRuleId) {
          console.log("分时预约的票啊啦啦啦啦啦");
          this.temporaryData = row;
          this.temporaryNext = next;
          // 获取分时预约的时段
          this.getAppointmentList(row.timeShareAppointmentRuleId);
          this.appointmentDialog = true;
          return;
        }
        next(row); //让计数器执行
        // this.SetCartId(res.id);
        this.setCartList(res);
        this.cartData = res.productItemList.map(item => {
          return {
            spuId: item.spuId,
            buyNum: item.buyNum
          };
        });
      });
    },
    //产品减1
    minusClick(row, next) {
      let data = {
        id: this.cartList.id,
        productId: row.productId, // 产品id
        spuId: row.spuId, // 产品的spuId
        buyNum: -1, // 购买数量
        buyChannels: 8, // 购买渠道
        businessCategory: 1, // 业务类型
        playDate: moment().valueOf() //游玩日期|当天
      };
      updateShoppingCart(data).then(res => {
        if (!res) {
          this.cartData = [];
          next();
          //清空购物车
          // this.clearCar();
          this.setCartList({});
          return;
        }
        next(row);
        this.setCartList(res);
        if (res.productItemList && res.productItemList.length) {
          this.cartData = res.productItemList.map(item => {
            return {
              spuId: item.spuId,
              buyNum: item.buyNum
            };
          });
        } else {
          this.cartData = []; // 处理 减完所有票后，切换下tab，步进器有缓存
        }
      });
    },
    // 选择分时预约 时段
    appointmentClick(timeId) {
      this.timeId = timeId;
      this.plusClick(this.temporaryData, this.temporaryNext);
      this.appointmentDialog = false;
      //用完后就清空临时变量
      this.timeId = null;
    },
    // 获取分时预约库存信息列表
    getAppointmentList(ruleId) {
      this.date = new Date().getTime();
      appointmentTimeRuleStock({ ruleId, date: this.date })
        .then(res => {
          this.nowTime = moment(res[0].currentTime).format("HH:mm");
          this.appointmentList = res;
        })
        .catch(() => {});
    },
    //计算是否不能选择
    isDisable(item) {
      //判断是不是今天
      let isToday = moment(this.date).format("L") === moment().format("L");
      //如果是今天并且时间已经过了，就disable
      if (this.nowTime > moment(item.endTime).format("HH:mm") && isToday) {
        return true;
      } else if (item.remainStock == 0) {
        //剩余库存为0
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";

// 顶部bar
.top-bar {
  height: 80px;
  z-index: 1000;
}

// 左侧门票分类
.left-ticket-type {
  position: absolute;
  left: 0;
  top: 0;
  width: @leftMenuWidth;
  height: 100%;
  ul {
    padding-top: 100px;
    li {
      height: 80px;
      font-size: 15px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .li-act {
      background-color: #f1f1f1;
    }
  }
  .type-count {
    font-size: 16px;
  }
}

// 主体内容
.slide {
  max-height: 1240px;
  ul {
    padding-left: 100px;
  }
}
.ticket-img {
  border-radius: @2x;
  width: 100px;
  height: 100px;
}
.card-text-wrap {
  max-width: 550px;
  p {
    line-height: 34px;
  }
  .notice {
    width: 180px;
  }
  .num-box {
    position: absolute;
    right: @3x;
    bottom: @2x;
  }
}

// 分时预约
.ul-wrap {
  width: 100%;
  display: flex;
  li {
    width: 30%;
    height: 100px;
    background-color: @primary;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 18px;
    padding-top: 20px;
    box-sizing: border-box;
    margin-right: 3%;
    &.disable {
      background: #ececec;
      color: #333;
      pointer-events: none;
    }
  }
}
</style>
