<!--
买票登记页
  表单类型说明：
  fieldType：字段类型：1文本框，2日期，3下拉框，4姓名，5手机号，6身份证，7生日，8性别，9证件照
-->
<template>
  <div class="wrapper hei">
    <!-- 返回菜单 -->
    <back text="登记顾客信息" @backEvent="backEvent"></back>
    <div
      class="container flex-grow slide"
      :class="{ 'slide-wrap': fixedElement }"
    >
      <!-- 登记买票人信息 -->
      <div
        class="card-lg"
        v-if="
          purchaserMemberInfo.visitorFieldList &&
            purchaserMemberInfo.visitorFieldList.length
        "
      >
        <p class="font-xl relative pb-2x mb-2x bd-b text-1">
          购票人资料
        </p>
        <BasicTravelerForm
          :form-item-data="purchaserMemberInfo"
          ref="ticketBuyerInfoRef"
          @distance="distance"
          @notDistance="notDistance"
        ></BasicTravelerForm>
      </div>
      <!-- 登记出游人信息 -->
      <div class="card-lg" v-if="nowVisitor">
        <p class="relative mb-2x font-xl">出游人资料</p>
        <a-row class="font-l mv-2x" v-if="nowVisitor.needActivate">
          <a-col :span="6">门票激活：</a-col>
          <a-col>
            <a-switch v-model="nowVisitor.activateNow"></a-switch>
            <span class="pl-x" v-show="nowVisitor.activateNow">开</span>
            <span class="pl-x" v-show="!nowVisitor.activateNow">关</span>
          </a-col>
        </a-row>
        <div
          v-for="(item, index) in nowVisitor.travelerList"
          :key="index"
          class="mb-2x"
        >
          <p class="font-xl relative pb-2x mb-2x bd-b text-1">
            门票：{{ nowVisitor.productName }}
          </p>
          <p class="font-xl primary mb-2x">游客{{ index + 1 }}:</p>
          <!-- <a-row
            class="font-l mb-2x"
            v-if="nowVisitor.needActivate && index == 0"
          >
            <a-col :span="6">门票激活：</a-col>
            <a-col>
              <a-switch v-model="nowVisitor.activateNow"> </a-switch>
              <span class="pl-x" v-show="nowVisitor.activateNow">开</span>
              <span class="pl-x" v-show="!nowVisitor.activateNow">关</span>
            </a-col>
          </a-row> -->
          <!-- 该门票需填写游客信息 -->
          <BasicTravelerForm
            v-if="
              (item.visitorFieldList && item.visitorFieldList.length) ||
                item.requiredPersonId
            "
            :form-item-data="item"
            ref="touristInfoRef"
            @distance="distance"
            @notDistance="notDistance"
          ></BasicTravelerForm>
          <!-- 该门票不需填写游客信息 -->
          <p v-else class="mb-4x gray font-l">
            该门票无需登记出游人资料，请填写下一张
          </p>
        </div>
        <!-- 分页 -->
        <div class="flex-between gray font-xl bg-white page p-2x">
          <p @click="pagePre" v-show="curPage > 1">
            <a-icon type="left-circle" theme="filled" />
            上一张
          </p>
          <span class="font-m">
            第 {{ curPage }} 张（ 共 {{ totalPage }} 张）
          </span>
          <p @click="pageNext" v-show="curPage != totalPage">
            下一张
            <a-icon type="right-circle" theme="filled" />
          </p>
        </div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div
      class="footer back-footer flex-between container"
      :class="{ 'fixed-btn': fixedElement }"
    >
      <p class="font-l">
        合计 {{ totalPage }} 张
        <!-- <span>已登记 {{ checkIn }} 张</span> -->
      </p>
      <a-button type="primary" class="btn" @click="getTicket">确 定</a-button>
    </div>
  </div>
</template>
<script>
import Back from "@/components/common/back";
import BasicTravelerForm from "./components/basic-traveler-form.vue";
import { visitorFieldInfo } from "../../api/cart";
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "RecordsBuying",
  components: {
    Back,
    BasicTravelerForm
  },
  props: {},
  data() {
    return {
      rules: {},
      showDialog: false,
      fixedElement: false, // 输入框聚焦，增加padding，使键盘不盖住输入框
      totalPage: 0, // 总共页数
      curPage: 1 // 当前是第几张门票
      // checkIn: 0 // 已登记的张数
    };
  },
  computed: {
    ...mapGetters("order", ["cartList", "purchaserMemberInfo", "visitorsList"]),
    nowVisitor: function() {
      if (this.visitorsList.length > 0) {
        let target = this.visitorsList[this.curPage - 1];
        return target;
      } else {
        return null;
      }
    }
  },
  created() {
    this.totalPage = this.visitorsList.length || 0;
    // 不需填写出游人，只需填写购票人的合计张数
    if (
      this.purchaserMemberInfo.visitorFieldList &&
      this.purchaserMemberInfo.visitorFieldList.length &&
      !this.totalPage
    ) {
      this.totalPage = 1;
    }
  },
  mounted() {
    this.setPage(this.curPage);
  },
  methods: {
    ...mapActions("order", ["setCartList", "setTouristInfo"]),
    ...mapMutations("order", ["setPage"]),
    // 分页：上一张
    pagePre() {
      this.curPage -= 1;
    },
    // 分页：下一张
    pageNext() {
      // 校验当前项，必填项是否校验通过
      let bool = true;
      if (this.$refs.touristInfoRef && this.$refs.touristInfoRef[0]) {
        bool = this.$refs.touristInfoRef[0].validateForm();
      }
      if (!bool) {
        return;
      }
      this.curPage += 1;
      this.setPage(this.curPage);
    },
    // 出游人表单填写校验
    checkForm() {
      let flag = false;
      this.visitorsList.forEach((item, index) => {
        item.travelerList.forEach(subItem => {
          subItem.visitorFieldList.some(thirdItem => {
            if (flag) {
              return true;
            }
            // 必填项，还没有填写
            if (thirdItem.required && !thirdItem.fieldValue) {
              this.$warning(`第${index + 1}张门票，信息未填写完整`);
              flag = true;
            }
          });
        });
      });
      return flag;
    },
    // 确定按钮
    getTicket() {
      // 购票人信息表单校验
      const bool =
        this.purchaserMemberInfo.visitorFieldList &&
        this.purchaserMemberInfo.visitorFieldList.length;
      if (bool && !this.$refs.ticketBuyerInfoRef.validateForm()) {
        this.$warning("购票人资料必填项校验不通过");
        return;
      }
      // 出游人信息表单校验
      if (this.checkForm()) {
        return;
      }
      let productTravelerList = this.visitorsList.map(item => {
        return {
          productId: item.productId,
          activateNow: item.activateNow,
          travelerList: item.travelerList
        };
      });
      let submitData = {
        id: this.cartList.id,
        purchaserMemberInfo: {
          visitorFieldList: this.purchaserMemberInfo.visitorFieldList
        },
        productTravelerList
      };
      visitorFieldInfo(submitData)
        .then(() => {
          // 存一份已填写的游客信息，在订单页返回时，回显数据
          const obj = {
            visitorsList: this.visitorsList, // 出游人信息
            purchaserMemberInfo: this.purchaserMemberInfo // 购票人信息
          };
          this.setTouristInfo(obj);
          this.$router.push({ name: "buy-order", query: { notclear: "1" } });
        })
        .catch(() => {});
    },
    // 顶部导航返回按钮
    backEvent() {
      // 清空购物车
      this.setCartList({});
    },
    // 键盘遮住输入框
    distance() {
      this.fixedElement = true;
    },
    notDistance() {
      this.fixedElement = false;
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.relative {
  left: -32px;
  top: 0;
}
.page {
  border-bottom-left-radius: @2x;
  border-bottom-right-radius: @2x;
}
/deep/.ant-switch {
  height: 32px;
  min-width: 60px;
  &::after {
    width: 28px;
    height: 28px;
  }
}
.slide-wrap {
  padding-bottom: 200px;
}
.fixed-btn {
  position: fixed;
  bottom: 360px;
  left: 0;
  right: @rightMenuWidth;
}
</style>
