<template>
  <keyboard
    type="1"
    v-model="numbers"
    @ok="ok"
    shadow="2px 2px 5px 1px rgba(0, 0, 0, 0.3)"
    place="请扫码或输入会员号/会员手机号"
    @clear="clear"
  >
    <!-- <a-input
      slot="input"
      allow-clear
      v-model="numbers"
      placeholder="请扫码或输入会员号/会员手机号"
    >
    </a-input> -->
  </keyboard>
</template>

<script>
import Keyboard from "@/components/common/keyboard";
import { memberInfoOne } from "@/api/member.js";
import { mapActions } from "vuex";

export default {
  name: "search-member",
  components: {
    Keyboard
  },
  data() {
    return {
      numbers: ""
    };
  },
  created() {},
  methods: {
    ...mapActions("common", ["updateMember"]),
    ok() {
      if (this.numbers.length === 0) {
        this.$message.warning("请扫码查询/输入手机号查询");
        return false;
      }
      memberInfoOne({ mobile: this.numbers })
        .then(res => {
          if (!res.id) {
            this.$message.warning("查询不到会员信息");
            return false;
          }
          this.updateMember(res);
          this.$emit("success", res.id, this.numbers);
          this.numbers = "";
        })
        .catch(() => {});
    },
    // 键盘清空
    clear() {
      this.numbers = "";
    }
  }
};
</script>

<style lang="less" scoped></style>
