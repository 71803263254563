import { render, staticRenderFns } from "./records-buying.vue?vue&type=template&id=995b51f8&scoped=true&"
import script from "./records-buying.vue?vue&type=script&lang=js&"
export * from "./records-buying.vue?vue&type=script&lang=js&"
import style0 from "./records-buying.vue?vue&type=style&index=0&id=995b51f8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "995b51f8",
  null
  
)

export default component.exports