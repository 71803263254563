<template>
  <div class="scan-pay">
    <!-- 顶部进度条 -->
    <div
      v-if="showStep"
      class="top-bar relative bg-white container flex-shrink b-shadow"
    >
      <a-steps :current="2">
        <a-step title="选票"></a-step>
        <a-step title="提交订单" />
        <a-step title="支付出票" />
      </a-steps>
    </div>
    <!-- 返回菜单 -->
    <back
      v-if="!showStep"
      text="扫码支付"
      @backEvent="backEvent"
      :url="backUrl"
    ></back>

    <!-- 顶部扫码提示语 -->
    <div class="content text-c">
      <p class="font-xxl">将微信或支付宝付款码</p>
      <p class="font-xxl mt-2x mb-2x">对准右下角扫描器，扫码支付</p>
      <p v-if="payCountdownDialog" class="bold font-xxxxl">
        将在
        <span class="red">{{ timeNum }}</span>
        秒后，返回首页
      </p>
      <!-- <img
        class="saoma"
        src="https://obs-qmp-android.obs.cn-south-1.myhuaweicloud.com/qumaipiao_k1/image/gif/saomaqupiao.gif"
      /> -->
    </div>

    <!-- 支付选择模块 -->
    <div class="new-pay-wrap" v-if="payMode.length">
      <div class="flex-between align-center">
        <span>
          <span class="font-l">应付：</span>
          <span class="red font-xxl">{{ totalFee | money }}</span>
        </span>
        <!-- <span class="flex-start align-center">
          <span>是否开启组合支付</span>
          <a-switch class="ml-1x mr-x" v-model="combinedPayment" />
          <span>{{ combinedPayment ? "开启" : "关闭" }}</span>
        </span> -->
      </div>
      <div class="pay-mode-wrap mt-1x">
        <ul class="pay-mode slide font-l flex-between align-center">
          <li
            v-for="(item, index) in payMode"
            :key="index"
            class="li-item pointer"
            @click="payModeClick(item)"
          >
            {{ item.payFormCategory == 1 ? "扫码支付" : item.payFormName }}
            <span
              class="span"
              :class="{ 'act-span': curIndex == item.payFormCategory }"
            >
              {{ item.payFormCategory == 1 ? "扫码支付" : item.payFormName }}
            </span>
          </li>
        </ul>
        <ul
          v-show="subPayMode.length"
          class="pay-mode slide font-l flex-between align-center"
        >
          <li
            class="li-item pointer"
            v-for="(subItem, subIndex) in subPayMode"
            :key="subIndex"
            @click="payModeClick2(subItem, subIndex)"
          >
            {{ subItem.payWayName }}
            <span
              class="span1"
              :class="{ 'act-span2': subCurIndex == subIndex }"
              >{{ subItem.payWayName }}</span
            >
          </li>
        </ul>
      </div>
      <component
        :is="payType"
        :prop-param="{
          combinedPayment,
          totalFee,
          orderNumber,
          formatId,
          subPayModeCode,
          subPayModeName,
          subPayModeId
        }"
      ></component>
      <a-button @click="cancelPay" class="cancel-pay-btn" type="primary" ghost
        >取消收款</a-button
      >
    </div>
    <div v-if="payMode.length == 0" class="new-pay-wrap text-c">
      <p class="font-xxxl mb-2x red">支付配置未开启</p>
      <span class="font-xl gray">请先去后台启用支付功能</span>
    </div>
  </div>
</template>
<script>
import Back from "@/components/common/back";
import { mapActions, mapGetters } from "vuex";
// 新版支付相关
import NewScan from "./components/new-scan.vue";
import OfflinePay from "./components/offline-pay"; // 自助机后台不会返回线下支付，先按窗口端搬过来
import DeductionPay from "./components/deduction-pay";
import HangBill from "./components/hang-bill";
import { merchantuthPayFormWayInfo } from "@/api/pay";

export default {
  name: "ScanPay",
  components: {
    Back,
    NewScan,
    OfflinePay,
    DeductionPay,
    HangBill
  },
  props: {},
  data() {
    return {
      orderNo: "", //订单号
      authCode: "", //付款码
      backUrl: "",
      payCountdownDialog: false, // 是否显示支付倒计时
      timeNum: 0, // 支付倒计时间
      timer: null, // 倒计时定时器
      showStep: false,
      // 新版支付相关
      combinedPayment: false, // 是否开启组合支付
      curIndex: "", // 默认显示扫码支付
      payMode: [], // 支付形式
      subPayMode: [], // 支付方式
      subCurIndex: 0,
      payType: "NewScan",
      totalFee: 0, // 订单支付总费用
      orderNumber: "", // 订单id
      formatId: "", // 业态id，用来判断打印
      subPayModeCode: "", // 第二支付形式code
      subPayModeName: "", // 第二支付形式名称
      subPayModeId: ""
    };
  },
  computed: {
    ...mapGetters("setting", ["machineDetail", "menuData"]),
    ...mapGetters("order", ["endOfPayConfig"])
  },
  created() {
    this.showStep = this.$route.query.step;
    // 新版支付相关
    this.getPayMode(); // 获取支付形式
    this.totalFee = this.$route.query.totalFee || 0;
    this.orderNumber = this.$route.query.orderNumber || "";
    this.formatId = this.$route.query.formatId || "";
    // 支付倒计时
    this.payCountdown();
    this.$speak("将微信或支付宝付款码对准右下角扫描器");
  },
  destroyed() {
    clearInterval(this.timer);
    this.payCountdownDialog = false;
  },
  methods: {
    ...mapActions("order", [
      "clearDiscount",
      "setCartList",
      "setEndOfPayConfig"
    ]),
    ...mapActions("common", ["updateMember"]),
    // 支付倒计时
    payCountdown() {
      this.timeNum = this.machineDetail.paymentCountdown;
      if (this.timeNum) {
        this.payCountdownDialog = true;
        this.timer = setInterval(() => {
          if (this.timeNum <= 0) {
            clearInterval(this.timer);
            this.payCountdownDialog = false;
            // 倒计时结束
            this.cancelPay();
            return;
          }
          this.timeNum = this.timeNum - 1;
        }, 1000);
      }
    },
    // 获取支付方式
    getPayMode() {
      merchantuthPayFormWayInfo()
        .then(res => {
          this.payMode = res || [];
          const item = (res && res[0]) || {};
          this.curIndex = item.payFormCategory; // 默认支付方式，取第一条数据
          if (item.authPayWayVOS && item.authPayWayVOS.length) {
            this.subPayMode = item.authPayWayVOS;
            this.mySwitch(item.payFormCategory, false);
          }
        })
        .catch(() => {});
    },
    // 切换支付形式
    payModeClick(item) {
      this.curIndex = item.payFormCategory;
      this.subCurIndex = 0;
      this.mySwitch(false, item);
    },
    mySwitch(i, item) {
      let index = "";
      item ? (index = item.payFormCategory) : (index = i);
      switch (index) {
        case 1: // 扫码支付：（含：线上支付、原生支付、钱柜子支付）
          this.payType = "NewScan";
          item && this.setSubPayMode(item);
          break;
        case 2: // 线下支付
          this.payType = "OfflinePay";
          item && this.setSubPayMode(item);
          break;
        case 3: // 挂账支付
          this.payType = "HangBill";
          item && this.setSubPayMode(item);
          break;
        case 4: // 抵扣支付
          this.payType = "DeductionPay";
          item && this.setSubPayMode(item);
          break;
        default:
          break;
      }
    },
    // 配置支付方式的子选项
    setSubPayMode(item) {
      if (item.authPayWayVOS && item.authPayWayVOS.length) {
        this.subPayMode = item.authPayWayVOS;
        this.getSubPayMode(this.subPayMode[0]);
      } else {
        this.subPayMode = [];
      }
    },
    // 拿出sub支付方式数据
    getSubPayMode(item) {
      this.subPayModeCode = item.payWayCode;
      this.subPayModeId = item.payWayId;
      this.subPayModeName = item.payWayName;
    },
    // 点击支付方式的子选项
    payModeClick2(subItem, i) {
      this.subCurIndex = i;
      console.log(subItem, "-*-----");
      this.getSubPayMode(subItem);
    },
    // 取消收款
    cancelPay() {
      this.setCartList({});
      this.clearDiscount();
      const url = this.endOfPayConfig.backUrl || this.menuData[0].url;
      // 是否需要清空会员信息
      if (this.endOfPayConfig.logoutMember) {
        this.updateMember({});
      }
      // 恢复配置信息到默认状态
      this.setEndOfPayConfig({
        logoutMember: true,
        backUrl: this.menuData[0].url || "/home"
      });
      this.$router.replace({ path: url });
    },
    // 返回按钮
    backEvent() {
      this.clearDiscount();
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";

// 顶部bar
.top-bar {
  height: 80px;
}
.content {
  margin: 60px auto 0;
}

.new-pay-wrap {
  width: 520px;
  margin: 60px auto 0;
  .pay-mode-wrap {
    background: #dbdfe6;
    border-radius: 6px;
  }
  .pay-mode {
    overflow-x: auto;
    height: 70px;
    .li-item {
      height: 50px;
      padding: 0 30px;
      flex-shrink: 0;
      line-height: 50px;
      position: relative;
      left: 0;
      top: 0;
      span {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 6px;
        overflow: hidden;
      }
      .span {
        width: 0;
        height: 0;
      }
      .span1 {
        height: 100%;
        width: 0;
      }
      .act-span {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        transition: all 0.5s;
        background: @primary;
        color: #fff;
        text-align: center;
      }
      .act-span2 {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        transition: all 0.5s;
        background: #1ec289;
        color: #fff;
        text-align: center;
      }
    }
  }
  .slide {
    overflow-y: hidden;
    overflow-x: auto;
  }
}
.cancel-pay-btn {
  width: 200px;
  height: 60px;
  border-radius: 30px;
  margin: 30px 0 0 160px;
}
</style>
